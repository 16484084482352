import { lazy } from 'react';

const lazyPages = {
  About: lazy(() => import('../Pages/About/About.page')),
  Privacy: lazy(() => import('../Pages/PrivacyPolicy/PrivacyPolicy.page')),
  OurDataHandlingDetails: lazy(() => import('../Pages/OurDataHandlingDetails/OurDataHandlingDetails.page')),
  FAQ: lazy(() => import('../Pages/FAQ/FAQ.page')),
  Institutions: lazy(() => import('../Pages/Institutions/Institutions.page')),
  ContactUs: lazy(() => import('../Pages/ContactUs/Contactus')),
  Onboarding: lazy(() => import('../Pages/auth/onboarding/Onboarding')),
  Photo: lazy(() => import('../Pages/auth/onboarding/components/Photo')),
  Certification: lazy(() => import('../Pages/auth/onboarding/components/Certification')),
  Social: lazy(() => import('../Pages/auth/onboarding/components/Social')),
  Bio: lazy(() => import('../Pages/auth/onboarding/components/Bio')),
  Review: lazy(() => import('../Pages/auth/onboarding/components/Review')),
  Communication: lazy(() => import('../components/Communication/Communication')),
  Performance: lazy(() => import('../components/Performance/Performance')),
  Account: lazy(() => import('../components/Account/Account')),
  Profile: lazy(() => import('../components/Profile/Profile')),
  Support: lazy(() => import('../Pages/ContactUs/Support')),
  MyProgram: lazy(() => import('../components/Programs/MyPrograms')),
  ProgramDetail: lazy(() => import('../components/Programs/ProgramDetail/ProgramDetail')),
  ProgramEditing: lazy(() => import('../components/Programs/EditProgram/EditProgram')),
  LessonDetail: lazy(() => import('../components/Programs/Lesson/LessonDetail')),
  CreateTPForm: lazy(() => import('../components/CreateTrainingProg/CreateTPForm')),
  // CreateTrainingProgramLayout: lazy(() => import('../layouts/CreateTraininggProgramlayout')),
  // ProgramDuration: lazy(() => import('../components/Programs/Create/ProgramDuration')),
  AddCourses: lazy(() => import('../components/Programs/Create/AddCourses')),
  AddModules: lazy(() => import('../components/Programs/Create/AddModules/AddModuleManagement')),
  AddLessons: lazy(() => import('../components/Programs/Create/AddLessons')),
  AddIntroVideo: lazy(() => import('../components/Programs/Create/AddIntroVideo')),
  PreviewProgram: lazy(() => import('../components/Programs/Create/PreviewProgram')),
  SaveAndPublishProgram: lazy(() => import('../components/Programs/Create/SaveAndPublishProgram')),
  AccountSecurity: lazy(() => import('../components/Account/AccountSecurity/AccountSecurity')),
  CloseAccount: lazy(() => import('../components/Account/CloseAccount/CloseAccount')),
  NotificationsSettings: lazy(() => import('../components/Account/NotificationsSettings/NotificationsSettings')),
  MessageSettings: lazy(() => import('../components/Account/MessageSettings/MessageSettings')),
  NotFound: lazy(() => import('../Pages/NotFound/Notfound')),
  Dashboard: lazy(() => import('../Pages/Dashboard/Dashboard'))
} as const;

// Preloaded critical pages
const Landing = require('../Pages/Landing.page').default;
const SignUp = require('../Pages/auth/SignUp').default;
const AddPhone = require('../Pages/auth/AddPhone').default;
const SignIn = require('../Pages/auth/SignIn').default;
const ForgotPassword = require('../Pages/auth/ForgotPassword').default;
const Otp = require('../Pages/auth/otp').default;
const CreateTPLayout = require('../layouts/CreateTraininggProgramlayout').default;

export const pages = {
  Landing,
  About: lazyPages.About,
  Privacy: lazyPages.Privacy,
  OurDataHandlingDetails: lazyPages.OurDataHandlingDetails,
  FAQ: lazyPages.FAQ,
  Institutions: lazyPages.Institutions,
  ContactUs: lazyPages.ContactUs,
  AddPhone,
  SignUp,
  SignIn,
  Otp,
  ForgotPassword,
  Onboarding: lazyPages.Onboarding,
  Photo: lazyPages.Photo,
  Certification: lazyPages.Certification,
  Social: lazyPages.Social,
  Bio: lazyPages.Bio,
  Review: lazyPages.Review,
  Dashboard: lazyPages.Dashboard,
  Communication: lazyPages.Communication,
  Performance: lazyPages.Performance,
  Account: lazyPages.Account,
  Profile: lazyPages.Profile,
  Support: lazyPages.Support,
  MyProgram: lazyPages.MyProgram,
  ProgramDetail: lazyPages.ProgramDetail,
  LessonDetail: lazyPages.LessonDetail,
  CreateTPForm: lazyPages.CreateTPForm,
  CreateTPLayout,
  EditProgram: lazyPages.ProgramEditing,
  // ProgramDuration: lazyPages.ProgramDuration,
  AddCourses: lazyPages.AddCourses,
  AddModules: lazyPages.AddModules,
  AddLessons: lazyPages.AddLessons,
  AddIntroVideo: lazyPages.AddIntroVideo,
  PreviewProgram: lazyPages.PreviewProgram,
  SaveAndPublishProgram: lazyPages.SaveAndPublishProgram,
  AccountSecurity: lazyPages.AccountSecurity,
  CloseAccount: lazyPages.CloseAccount,
  NotificationsSettings: lazyPages.NotificationsSettings,
  MessageSettings: lazyPages.MessageSettings,
  NotFound: lazyPages.NotFound,
};
