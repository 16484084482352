"use client";

import {
  Box,
  Container,
  SimpleGrid,
  Stack,
  Text,
  Flex,
  useColorModeValue,
  VisuallyHidden,
  chakra,
  Image,
} from "@chakra-ui/react";
import XLogo from "../../assets/images/Xlogo.png"
import 'primereact/resources/themes/saga-blue/theme.css';  // Theme
import 'primereact/resources/primereact.min.css';         // Core CSS
import 'primeicons/primeicons.css';
import { FaInstagram, FaYoutube } from "react-icons/fa";
import { ReactNode } from "react";
import "./Footer.css";
import { Logo } from "../common/Logo";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { useFooter } from "./Footer.hooks";
import { langs } from "../../constants";

const ListHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Text fontWeight={"700"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

const SocialButton = ({
  children,
  label,
  href,
}: {
  children: ReactNode;
  label: string;
  href: string;
}) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};
export default function LargeWithLogoCentered() {
  const { selectedLang, setSelectedLang } = useFooter()
  return (
    <Box
      w="100%"
      bg={useColorModeValue("#FAFBFC", "gray.900")}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container fontSize="14.6px" as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid columns={{ base: 1, sm: 2, md: 4, lg: 5 }} spacing={8}>
          <Stack align={"flex-start"}>
            <ListHeader>Get Started</ListHeader>
            <Box as={Link} to="/contact-us">
              Contact Sales
            </Box>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Products</ListHeader>
            <Box as="a" href={"https://liahstudy.online"} target="_blank">
              LiahStudy
            </Box>
            <Box as="a" href={"https://apply.tekstedia.com"} target="_blank">
              ApplyTekstedia
            </Box>
          </Stack>
          <Stack textAlign="left" align={"flex-start"}>
            <ListHeader>Solutions</ListHeader>
            <Box as="a" href={"#"}>
              Digital Transformation
            </Box>
            <Box as="a" href={"#"}>
              E-learning
            </Box>
            <Box as="a" href={"#"}>
              New product development
            </Box>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Resources</ListHeader>
            <Box as="a" href={"#"}>
              Customers
            </Box>
            <Box as="a" href={"#"}>
              Partners
            </Box>
            <Box as="a" href={"#"}>
              Affiliates
            </Box>
            <Box as="a" href={"#"}>
              Developers
            </Box>
            <Box as="a" href={"#"}>
              Security
            </Box>
            <Box as="a" href={"#"}>
              Support
            </Box>
            <Box as="a" href={"#"}>
              Learning Campus
            </Box>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Company</ListHeader>
            <Box as="a" href={"#"}>
              About us
            </Box>
            <Box as="a" href={"#"}>
              Mission
            </Box>
            <Box as="a" href={"#"}>
              Leadership
            </Box>
            <Box as="a" href={"#"}>
              Newsroom
            </Box>
            <Box as="a" href={"#"}>
              Careers
            </Box>
            <Box as="a" href={"#"}>
              Contact us
            </Box>
          </Stack>
        </SimpleGrid>
      </Container>
      <Box py={10}>
        <Box>
          <Box className="downFooter-container">
            <Box className="inside-container" fontSize="16px">
              <div className="left-side">
                <nav
                  aria-label="Footer language select navigation"
                  className="langSelect"
                >
                  <Box as={Dropdown} w="100%"
                    rounded="md"
                    background="transparent"
                    fontFamily="body"
                    border="1px solid gainsboro" name="level" value={selectedLang} onChange={(e: { value: any }) => setSelectedLang(e.value)} options={langs} optionLabel="language"
                    minH="40px" p=".5rem"
                    placeholder="Language" className="w-full md:w-14rem"
                  />
                </nav>
                <Text as={Link} to="/privacy">Privacy</Text>
                <Text as={Link} to="/data-handling">Legal</Text>
                <Text as={Link} to="/data-handling">Cookies</Text>
              </div>
              <Stack direction={"row"} spacing={6}>

                <SocialButton label={"Twitter"} href={"https://x.com"}>
                  <Image w="12px"
                    h="12px" src={XLogo} />
                </SocialButton>
                <SocialButton label={"YouTube"} href={"https://youtube.com"}>
                  <FaYoutube />
                </SocialButton>
                <SocialButton label={"Instagram"} href={"https://instagram.com"}>
                  <FaInstagram />
                </SocialButton>
              </Stack>
            </Box>
          </Box>
        </Box>
        <Flex
          align={"center"}
          _before={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            mr: 8,
          }}
          _after={{
            content: '""',
            borderBottom: "1px solid",
            borderColor: useColorModeValue("gray.200", "gray.700"),
            flexGrow: 1,
            ml: 8,
          }}
        >
          <Logo />
        </Flex>
        <Text pt={6} fontSize={"sm"} textAlign={"center"}>
          © {new Date().getFullYear()} Liah Technologies. All rights reserved
        </Text>
      </Box>
    </Box>
  );
}
