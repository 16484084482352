import { Center, keyframes, CenterProps, Spinner, Text, VStack, Box, useColorModeValue, Image, Icon } from "@chakra-ui/react";
import "animate.css";
import LiahLogo from "../../assets/images/LiahTutor_Logo_icon.png";
import { motion } from "framer-motion";
import { MotionBox } from "../common/MotionElements";
import { MdSchool } from "react-icons/md";

interface Props extends CenterProps {
  loadingText?: string;
}

const spinAround = keyframes`
  0% { transform: rotate(0deg) translateX(50px) rotate(0deg); }
  100% { transform: rotate(360deg) translateX(50px) rotate(-360deg); }
`;

const rotateImage = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loading = ({
  h = "calc(100vh - 60px)",
  loadingText = "Loading, please wait...",
  ...rest
}: Props) => {
  const spinnerAnimation = `${spinAround} 2s linear infinite`;
  const imageAnimation = `${rotateImage} 10s linear infinite`;
  const spinnerColor = useColorModeValue('primary', 'blue.300');

  return (
    <Center className="animate__animated fadeInUpBig">
      <VStack m="0 auto" mt="20%">
        <MotionBox
          initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}
          position="relative" width="40px" height="40px">
          <Image
            src={LiahLogo}
            alt="Loading Image"
            animation={imageAnimation}
            borderRadius="full"
            boxShadow="none"
          />
          <Icon as={MdSchool}
            color={spinnerColor}
            size="sm"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            animation={spinnerAnimation}
          />
        </MotionBox>
      </VStack>
    </Center>

  );
};

export default Loading;
