import { useEffect, useState } from "react";
import i18n from "i18next";
import { principalLangKey } from "../../constants";
import { liahTutorLangs } from "../../../types";

export const useFooter = () => {
  const [selectedLang, setSelectedLang] = useState<liahTutorLangs["langs"]>("English");

  // Retrieve language from localStorage on initial render
  useEffect(() => {
    const storedLang = localStorage.getItem(principalLangKey) as liahTutorLangs["langs"] || "English";
    setSelectedLang(storedLang);
    i18n.changeLanguage(storedLang === "English" ? "en" : "fr");
  }, []);

  // Function to toggle the language
  const toggleLanguage = (language: liahTutorLangs["langs"]) => {
    localStorage.setItem(principalLangKey, language);
    i18n.changeLanguage(language === "English" ? "en" : "fr");
    setSelectedLang(language);
  };

  return {
    selectedLang,
    setSelectedLang: toggleLanguage,
  };
};
