import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import authSlice from "./features/slices/authSlice";
import trainingProgramDataSlice from "./features/slices/trainingPrograms";
import utilitySlice from "./features/slices/utitlitySlice";
import trainingProgramId from "./features/slices/trainingProgramId";
import middlewares from "./features/middleware";
import currentTrainingProgramDataSlice from "./features/slices/currentTrainingProgram";
import newsLetterSlice from "./features/slices/newsletter";

// Root reducer combining all slices
const rootReducer = combineReducers({
  trainingProgram: trainingProgramDataSlice,
  newsletter: newsLetterSlice,
  currentTrainingProgram: currentTrainingProgramDataSlice,
  utility: utilitySlice,
  auth: authSlice,
  trainingProgramId: trainingProgramId,
});

// Create the store using the persisted reducer
export const store = configureStore({
  reducer: rootReducer,
  middleware: (defaultMiddleware) => defaultMiddleware().concat(middlewares),
});

// Define the AppDispatch type
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
