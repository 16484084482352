import React, { useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { routes } from "./routes";
import Notfound from "../Pages/NotFound/Notfound";
import { CustomizedModal } from "../components/Modal/Modal";
import ErrorModal from "../components/common/ErrorModal";
import { ErrorType } from "../../types";

// Scroll to top on route change
const ScrollToTop: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return null;
};

// Page transition variants
const pageVariants = {
  initial: {
    opacity: 0,
    y: "-50%",  // Drop in from the middle of the screen (above)
    scaleY: 0,  // Start with the height scaled down (compressed)
    originY: 0.3,  // Anchor the transform to the middle of the page
  },
  animate: {
    opacity: 1,
    y: "0%",  // Move to its final position (centered)
    scaleY: 1,  // Expand vertically from the middle to its full height
    transition: {
      duration: 0.1,  // Duration for the entry animation
      ease: "easeOut",  // Smoother animation
    },
  },
  exit: {
    opacity: 0,
    y: "50%",  // Drop out of the screen (below)
    scaleY: 0,  // Compress vertically as it exits
    originY: 0.5,  // Again, anchor to the middle
    transition: {
      duration: 0.2,  // Duration for the exit animation
      ease: "easeIn",  // Make exit a bit quicker with easeIn
    },
  },
};

const pageTransition = {
  duration: 0.2,
};

interface AppContentProps {
  isModal: boolean;
  isOpen: boolean;
  handleClose: () => void;
  error: ErrorType | null;
}

const AppContent: React.FC<AppContentProps> = ({ isModal, isOpen, handleClose, error }) => {

  return (
    <>
      <ScrollToTop />
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element}>
            {route.children?.map((child) => (
              <Route
                key={child.path}
                path={child.path}
                element={child.element}
              >
                {child.children?.map((grandchild) => (
                  <Route
                    key={grandchild.path}
                    path={grandchild.path}
                    element={grandchild.element}
                  >
                    {grandchild.children?.map((greatGrandchild) => (
                      <Route
                        key={greatGrandchild.path}
                        path={greatGrandchild.path}
                        element={greatGrandchild.element}
                      />
                    ))}
                  </Route>
                ))}
              </Route>
            ))}
          </Route>
        ))}
        {/* Our 404 page route */}
        <Route path="*" element={<Notfound />} />
      </Routes>
      {/* </AnimatePresence> */}

      {isModal && <CustomizedModal />}
      {error && <ErrorModal isOpen={isOpen} onClose={handleClose} error={error} />}
    </>
  );
};

export default AppContent;
