import { createSelector } from "@reduxjs/toolkit";
import { ApiException, PrincipalResponse } from "../types";
import { AsyncState } from ".";

const selectAuth = (state: {
  auth: AsyncState<PrincipalResponse, ApiException>;
}) => state.auth;

export const selectAuthPayload = createSelector([selectAuth], (auth) => ({
  payload: auth.payload,
  loading: auth.loading,
}));
