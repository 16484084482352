import { useEffect, useRef, useState } from "react";
import {
  Course,
  ErrorType,
  Lesson,
  Module,
  ProgramMetrics,
  enteredQuizData,
} from "../../types";
import { useDisclosure } from "@chakra-ui/react";
import { useAppDispatch } from "../app/hooks";
import { useToast } from "@chakra-ui/react";
import {
  currentSavedNotesKey,
  currentPrincipalTPKey,
  principalTokenKey,
} from "../constants";
import { currentProgramActions } from "../store/features/slices/currentTrainingProgram";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { saveFile } from "../utils/db";
import { currentPathName, currentSearchParams, isTokenExpired } from "../utils";
import { userActions } from "../store/features/slices/authSlice";
import eventBus from "../utils/eventbus";
import { WarningIcon } from "@chakra-ui/icons";

export const useRecord = () => {
  const navigate = useNavigate();
  function goPrevious() {
    navigate(-1);
  }
  return {
    goPrevious,
  };
};

export const useProgramMetrics = (): ProgramMetrics => {
  const [data, setData] = useState<ProgramMetrics>({
    engagementRates: [],
    completionRates: [],
    categories: [],
  });

  useEffect(() => {
    // Simulate fetching or generating data with a delay
    const fetchData = () => {
      const categories = [
        "2023-01-01T00:00:00.000Z",
        "2023-02-01T00:00:00.000Z",
        "2023-03-01T00:00:00.000Z",
        "2023-04-01T00:00:00.000Z",
        "2023-05-01T00:00:00.000Z",
        "2023-06-01T00:00:00.000Z",
        "2023-07-01T00:00:00.000Z",
      ];

      const engagementRates = [65, 75, 80, 90, 85, 100, 95];
      const completionRates = [50, 60, 70, 65, 80, 90, 85];

      setData({
        engagementRates,
        completionRates,
        categories,
      });
    };

    fetchData();
  }, []);

  return data;
};
export const useSessionExpiredModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return {
    isOpen,
    onOpen,
    onClose,
    show: () => onOpen(),
  };
};

export const useQuiz = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const currentTPString = localStorage.getItem(currentPrincipalTPKey);
  const [selectedLesson, setSelectedLesson] = useState<Lesson | null>();
  const [currents, setCurrents] = useState({
    selectedCourseId: "",
    selectedModuleId: "",
  });
  const [currentLessonList, setCurrentLessonList] = useState<Lesson[] | null>();
  const [newQuiz, setNewQuiz] = useState<enteredQuizData>({
    question: "",
    answer: "",
    possibleAnswers: ["", "", "", ""],
  });
  const [quizzes, setQuizzes] = useState<enteredQuizData[]>([]);
  const [isLoading, setIsCatLoading] = useState(false);
  const [errorr, setError] = useState("");

  useEffect(() => {
    if (currentTPString) {
      const currentTP = JSON.parse(currentTPString);
      const currentLessonList = currentTP.payload?.trainingProgram?.courses
        .find((c: Course) => c._id === currents.selectedCourseId)
        ?.modules.find(
          (m: Module) => m._id === currents.selectedModuleId
        ).lessons;
      setCurrentLessonList(currentLessonList);
    }
  }, [currentTPString, currents]);

  const addQuiz = async (
    quiz: enteredQuizData,
    selectedCourseId: string,
    selectedModuleId: string
  ) => {
    setCurrents({ selectedCourseId, selectedModuleId });

    const selectedLessonId = localStorage.getItem("_SelectedLessonId") || "";
    if (!selectedLessonId) {
      toast({
        title: "No Lesson Selected",
        description: "Please select a lesson to add the quiz to.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const currentTPString = localStorage.getItem(currentPrincipalTPKey);
    if (!currentTPString) {
      toast({
        title: "No Training Program",
        description:
          "No training program found. Please create or select a training program.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setQuizzes((prev) => [...prev, quiz]);
    const currentTP = JSON.parse(currentTPString);
    const currentTPId = currentTP.payload?.trainingProgram?._id;
    if (!currentTPId) {
      toast({
        title: "Invalid Training Program",
        description: "The selected training program is invalid.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    setIsCatLoading(true);

    try {
      await dispatch(
        currentProgramActions.addQuizThunk({
          ids: {
            id: currentTPId,
            courseId: selectedCourseId,
            moduleId: selectedModuleId,
            lessonId: selectedLessonId,
          },
          quizData: {
            question: quiz.question,
            possibleAnswers: quiz.possibleAnswers,
            answer: quiz.answer,
          },
        })
      ).unwrap();

      setError("");
      setNewQuiz({
        question: "",
        answer: "",
        possibleAnswers: ["", "", "", ""],
      });
      toast({
        title: "Quiz created.",
        description: "Your quiz has been successfully added.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Something went wrong, please try again.";
      setError(errorMessage);
      toast({
        title: "Oops Quiz Builder Error",
        description: errorMessage,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsCatLoading(false);
    }
  };

  const updateQuiz = (index: number, quiz: enteredQuizData) => {
    setQuizzes((prev) => prev.map((q, i) => (i === index ? quiz : q)));
  };

  const removeQuiz = (index: number) => {
    setQuizzes((prev) => prev.filter((_, i) => i !== index));
  };

  return {
    newQuiz,
    setNewQuiz,
    selectedLesson,
    setSelectedLesson,
    currentLessonList,
    quizzes,
    addQuiz,
    updateQuiz,
    removeQuiz,
    isLoading,
  };
};

export const useAddIntroVideo = () => {
  const [introVideo, setIntroVideo] = useState<File | null>(null);
  const toast = useToast();
  const [isLoading, setIsCatLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentTPString = localStorage.getItem(currentPrincipalTPKey);
  const [currentTPId, setCurrentTPId] = useState("");
  const handleFileUpload = (file: File | undefined) => {
    setIntroVideo(file || null);
  };

  const checkVideoDuration = (file: File): Promise<number> => {
    return new Promise((resolve, reject) => {
      const videoElement = document.createElement("video");
      videoElement.preload = "metadata";

      videoElement.onloadedmetadata = () => {
        resolve(videoElement.duration);
      };

      videoElement.onerror = () => {
        reject("Failed to load video metadata.");
      };

      videoElement.src = URL.createObjectURL(file);
    });
  };

  const onSubmitIntroVideo = async () => {
    if (!introVideo) {
      toast({
        title: "Oops!",
        description: "Please select a video file to upload.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    try {
      const duration = await checkVideoDuration(introVideo);
      if (duration > 300) {
        toast({
          title: "Video too long.",
          description: "Please select a video that is less than 5 minutes.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        return;
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: "Failed to check video duration. Please try again.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    if (currentTPString) {
      const currentTP = JSON.parse(currentTPString);
      const currentTPId: string = currentTP.payload?._id;
      setCurrentTPId(currentTPId);
      await saveFile(`introVideo_${currentTPId}`, introVideo).then(() => {});
      try {
        setIsCatLoading(true);
        await dispatch(
          currentProgramActions.addIntroVideoThunk({
            id: currentTPId,
            introVideo: introVideo,
          })
        )
          .unwrap()
          .then(() => {
            setError("");
            toast({
              title: "Video Added.",
              description:
                "Your Introductory video has been successfully added.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
            setIntroVideo(null);
            goNext();
          });
      } catch (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "Something went wrong, please try again.";
        setError(errorMessage);
        toast({
          title: "An error occurred.",
          description: errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setIsCatLoading(false);
      }
    }
  };

  const goNext = () => {
    if (introVideo)
      navigate("/dashboard/programs/create-program/save-and-publish-program");
  };

  return {
    isLoading,
    error,
    onSubmitIntroVideo,
    handleFileUpload,
    introVideo,
    currentTPId,
  };
};

interface SavedLocalNotes {
  currentModuleId: string;
  html: string;
}

export const useManageEditor = () => {
  const [inZenMode, setInZenMode] = useState(false);
  const [model, setModel] = useState("");
  const savedLocalNote1 = localStorage.getItem(currentSavedNotesKey) || "";

  const savedLocalNotes: SavedLocalNotes | null = savedLocalNote1
    ? JSON.parse(savedLocalNote1)
    : null;
  const selectedModuleID = localStorage.getItem("__SelectedModule") || "";

  useEffect(() => {
    if (
      savedLocalNotes &&
      savedLocalNotes.currentModuleId === selectedModuleID
    ) {
      setModel(savedLocalNotes.html);
    }
  }, [selectedModuleID, savedLocalNotes]);

  return { model, setModel, inZenMode, setInZenMode };
};

export const useFormatNotes = () => {
  const [activeFormats, setActiveFormats] = useState<{
    [key: string]: boolean;
  }>({
    bold: false,
    italic: false,
    "unordered-list": false,
    "ordered-list": false,
    quote: false,
    code: false,
    link: false,
  });

  const getNodeFormatting = (tagName: string) => {
    const selection = window.getSelection();
    if (!selection) return false;

    const range = selection.getRangeAt(0);
    const container = range.commonAncestorContainer;
    const parentNode =
      container.nodeType === 3 ? container.parentNode : container;
    if (parentNode) return parentNode.nodeName.toLowerCase() === tagName;
  };

  const toggleFormat = (tagName: string, props: any = {}) => {
    const selection = window.getSelection();
    if (!selection) return;

    const range = selection.getRangeAt(0);
    const selectedText = range.toString();
    if (!selectedText) return;

    if (getNodeFormatting(tagName)) {
      // Remove formatting
      const container = range.commonAncestorContainer;
      const parentNode =
        container.nodeType === 3 ? container.parentNode : container;
      if (parentNode) {
        const textNode = document.createTextNode(parentNode.textContent || "");
        parentNode.parentNode?.replaceChild(textNode, parentNode);
      }
    } else {
      // Apply formatting
      const newNode = document.createElement(tagName);
      Object.keys(props).forEach((key) => {
        newNode.setAttribute(key, props[key]);
      });
      newNode.appendChild(document.createTextNode(selectedText));
      range.deleteContents();
      range.insertNode(newNode);
    }
  };

  const handleFormat = (format: string) => {
    toggleFormat(format);
    setActiveFormats((prev) => ({
      ...prev,
      [format]: !prev[format],
    }));
  };

  const handleLinkFormat = () => {
    const selection = window.getSelection();
    const range = selection?.getRangeAt(0);
    const selectedText = range?.toString();
    if (selectedText) {
      const url = prompt("Enter URL:");
      if (url) {
        toggleFormat("a", { href: url, target: "_blank" });
        setActiveFormats((prev) => ({ ...prev, link: !prev.link }));
      }
    }
  };

  return {
    handleLinkFormat,
    handleFormat,
    activeFormats,
  };
};

interface UseUploadReturn {
  progress: number;
  isUploading: boolean;
  uploadFile: (
    file: File,
    url: string,
    onProgress?: (progress: number) => void
  ) => Promise<void>;
}

export const useUpload = (): UseUploadReturn => {
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  const uploadFile = async (
    file: File,
    url: string,
    onProgress?: (progress: number) => void
  ) => {
    setIsUploading(true);
    setProgress(0);

    const formData = new FormData();
    formData.append("file", file);

    try {
      await axios.post(url, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event) => {
          const total = event.total ?? 1;
          const percentCompleted = Math.round((event.loaded * 100) / total);
          setProgress(percentCompleted);
          if (onProgress) {
            onProgress(percentCompleted);
          }
        },
      });

      // Handle success, e.g., show a success message or update state
    } catch (error) {
      // Handle error, e.g., show an error message
    } finally {
      setIsUploading(false);
    }
  };

  return { progress, isUploading, uploadFile };
};

export const useContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const toast = useToast();

  const handleSubmit = async (values: {
    name: string;
    email: string;
    message: string;
  }) => {
    setIsSubmitting(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      toast({
        title: "Message Sent",
        description:
          "Your message has been successfully sent. We will get back to you soon.",
        status: "success",
        duration: 9000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description:
          "There was an error sending your message. Please try again later.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return {
    isSubmitting,
    handleSubmit,
  };
};

// Category Cache to store data globally in memory
let categoryCache: string[] | null = null;

export const useFetchCategories = () => {
  const dispatch = useAppDispatch();
  const [categories, setCategories] = useState<string[]>([]);
  const [isCatLoading, setIsCatLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [catError, setCatError] = useState<string>("");
  const [catOptions, setCatOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchCategories = async () => {
      // If categories are already cached, use them
      if (categoryCache) {
        setCategories(categoryCache);
        setCatOptions(categoryCache.map((c) => c));
        return; // Skip fetching from API if already cached
      }

      // If categories are not cached, fetch from the API
      setIsCatLoading(true);
      setCatError("");
      try {
        const response = await dispatch(
          currentProgramActions.fetchCategoriesThunk()
        );
        const fetchedCategories: string[] = response.payload as string[];

        // Store categories in the cache
        categoryCache = fetchedCategories;

        setCategories(fetchedCategories);
        setCatOptions(fetchedCategories?.map((c) => c));
      } catch (err) {
        setIsError(true);
        const errorMessage =
          err instanceof Error ? err.message : "Failed to fetch categories";
        setCatError(errorMessage);
      } finally {
        setIsCatLoading(false);
      }
    };

    fetchCategories();
  }, [dispatch]);

  return { isError, categories, isCatLoading, catError, catOptions };
};

// Language Cache to store data globally in memory
let languagesCache: string[] | null = null;

interface Country {
  languages?: { [key: string]: string };
}

export const useFetchLanguages = () => {
  const [languages, setLanguages] = useState<string[]>([]);
  const [isLangLoading, setIsLangLoading] = useState(false);
  const [isLError, setIsLError] = useState(false);
  const [langError, setLangError] = useState<string>("");

  useEffect(() => {
    const fetchLanguages = async () => {
      // If languages are cached, use them
      if (languagesCache) {
        setLanguages(languagesCache);
        return; // Skip fetching if cached
      }

      // If languages are not cached, fetch from the API
      setIsLangLoading(true);
      try {
        const response = await fetch("https://restcountries.com/v3.1/all");
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data: Country[] = await response.json(); // Properly type the API response

        // Extract unique languages, ensuring we only collect strings
        const allLanguages: string[] = Array.from(
          new Set(
            data.flatMap((country: Country) =>
              country.languages ? Object.values(country.languages) : []
            )
          )
        ).sort();

        // Cache the fetched languages
        languagesCache = allLanguages;

        // Set state with fetched languages
        setLanguages(allLanguages);
        setIsLError(false);
      } catch (err) {
        setIsLError(true);
        setLangError("Error fetching languages");
      } finally {
        setIsLangLoading(false);
      }
    };

    fetchLanguages();
  }, []);

  return { isLError, languages, isLangLoading, langError };
};

interface tagsI {
  _id: string;
  name: string;
  __v: string;
}

// Global cache for tags
let tagsCache: tagsI[] | null = null;

export const useFetchTags = () => {
  const dispatch = useAppDispatch();
  const [tags, setTags] = useState<tagsI[]>([]);
  const [isTagsLoading, setIsTagsLoading] = useState(false);
  const [isTError, setIsTError] = useState(false);
  const [tagsError, setTagsError] = useState<string>("");
  const [tagOptions, setTagOptions] = useState<string[]>([]);

  useEffect(() => {
    const fetchTags = async () => {
      // Use cached tags if available
      if (tagsCache) {
        setTags(tagsCache);
        setTagOptions(tagsCache.map((t) => t.name));
        return;
      }

      // Fetch tags from the API if not cached
      setIsTagsLoading(true);
      try {
        const response = await dispatch(currentProgramActions.fetchTagsThunk());
        const fetchedTags: tagsI[] = response.payload as tagsI[];

        // Cache the fetched tags
        tagsCache = fetchedTags;

        // Set state with fetched tags
        setTags(fetchedTags);
        setTagOptions(fetchedTags.map((t) => t.name));
        setIsTError(false);
      } catch (err) {
        setIsTError(true);
        const errorMessage =
          err instanceof Error ? err.message : "Failed to fetch tags";
        setTagsError(errorMessage);
      } finally {
        setIsTagsLoading(false);
      }
    };

    fetchTags();
  }, [dispatch]);

  return { isTError, isTagsLoading, tagsError, tagOptions };
};

export const useOnChangeQueryParams = () => {
  const navigate = useNavigate();
  return (consumer?: (params: URLSearchParams) => void) => {
    const params = currentSearchParams();
    consumer?.(params);
    const arr: string[] = [];
    for (const [k, v] of params) {
      arr.push(`${k}=${v}`);
    }
    navigate(`${currentPathName()}?${arr.join("&")}`);
  };
};

export const useScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
};

export const useHandleOtpInput = () => {
  const value1 = useRef<HTMLInputElement | null>(null);
  const value2 = useRef<HTMLInputElement | null>(null);
  const value3 = useRef<HTMLInputElement | null>(null);
  const value4 = useRef<HTMLInputElement | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e: React.FormEvent) => {
    // e.preventDefault();
    // if (
    //   !value1.current ||
    //   !value1.current ||
    //   !value2.current ||
    //   !value3.current
    // ) {
    //   toast({
    //     title: "Error",
    //     description: "Invalid OTP",
    //     status: "error",
    //     duration: 3000,
    //     isClosable: true,
    //   });
    //   return;
    // }
    // setIsLoading(true);
    // dispatch(
    //   userActions.verifyOtp({
    //     email: localStorage.getItem(emailkey) ?? "",
    //     otp: `${value1.current?.value}${value2.current?.value}${value3.current?.value}${value4.current?.value}`,
    //   })
    // )
    //   .unwrap()
    //   .then((res) => {
    //     setIsLoading(false);
    //     toast({
    //       title: res.message,
    //       status: "success",
    //       duration: 3000,
    //       isClosable: false,
    //     });
    //     const next = queryString.next
    //       ? `${res.next}?next=${queryString.next}`
    //       : res.next;
    //     navigate(next);
    //   })
    //   .catch(() => {
    //     setIsLoading(false);
    //     toast({
    //       title: "Failed",
    //       description: "Invalid OTP",
    //       status: "error",
    //       duration: 3000,
    //       isClosable: true,
    //     });
    //   });
  };

  const handleNext = () => {};
  const clearInput = () => {
    value1?.current && (value1.current.value = "");
    value2?.current && (value2.current.value = "");
    value3?.current && (value3.current.value = "");
    value4?.current && (value4.current.value = "");
  };

  return {
    value1,
    value2,
    value3,
    value4,
    onSubmit,
    isLoading,
    handleNext,
    clearInput,
  };
};

export const useListenToEvent = () => {
  const [fn, setFn] = useState<(() => void) | undefined>(undefined);
  const handleExpiredSession = () => {
    const tkn = localStorage.getItem(principalTokenKey);
    if (tkn && isTokenExpired(tkn)) {
      eventBus.emit("showErrorModal", {
        title: "Session Expired",
        message: "Your session has expired. Please log in again to continue.",
        icon: WarningIcon,
        buttonText: "Log In",
        buttonAction: fn,
      });
    }
  };

  useEffect(() => {
    const events = [
      "click",
      "wheel",
      "mousedown",
      "mouseup",
      "scroll",
      "keydown",
      "keyup",
      "keypress",
    ];

    // Add event listeners for all specified events
    events.forEach((event) => {
      window.addEventListener(event, handleExpiredSession);
    });

    // Cleanup the event listeners when the component is unmounted
    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, handleExpiredSession);
      });
    };
  }, [fn]);

  return {
    setHandler: (callback: () => void) => setFn(() => callback),
    handler: (fn: () => void) => () => setFn(fn),
  };
};

export const useCheckTokenValidity = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isExpired, setIsExpired] = useState(false);
  const tkn = localStorage.getItem(principalTokenKey);
  const [error, setError] = useState<ErrorType | null>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tkn) setIsExpired(isTokenExpired(tkn));
  }, []);

  useEffect(() => {
    if (!isExpired) return;
    eventBus.emit("showErrorModal", {
      title: "Session Expired",
      message: "Your session has expired. Please log in again to continue.",
      icon: WarningIcon,
      buttonText: "Log In",
      buttonAction: handleButtonAction,
    });
  }, [isExpired]);

  const handleButtonAction = () => {
    handleClose();
    handleSignout();
  };

  const handleSignout = () => {
    dispatch(userActions.signout());
    const pathname = window.location.pathname;
    if (pathname.includes("dashboard"))
      window.location.href = "/signin?next=" + pathname;
  };

  const handleClose = () => {
    setError(null);
    setIsOpen(false);
  };

  return {
    error,
    isOpen,
    setError,
    setIsOpen,
    handleButtonAction,
  };
};
