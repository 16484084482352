import React, { Suspense, useEffect, useRef } from "react";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import AppRouter from "./routes/router";
import { principalStorageKey } from "./constants";
import { useAppDispatch } from "./app/hooks";
import { userActions } from "./store/features/slices/authSlice";
import Loading from "./components/loading/Loading";
import { liahtutorTheme } from "./assets/styles/theme";
import "./i18n";

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const userLoadRef = useRef(false);

  useEffect(() => {
    console.log(
      "%cWARNING!",
      "color: red; background: yellow; font-size: 20px; font-weight: bold;"
    );
    console.log(
      "%cUsing this console may allow attackers to impersonate you and steal your information using an attack called Self-XSS.",
      "color: blue;"
    );
    console.log(
      "%cDo not enter or paste code that you do not understand.",
      "color: blue;"
    );

    const storeUser = localStorage.getItem(principalStorageKey);

    if (!userLoadRef.current) {
      if (storeUser) {
        dispatch(
          userActions.loadUserFromStorage(JSON.parse(storeUser)?.payload)
        );
      }
      dispatch(userActions.getUserInfo());
      userLoadRef.current = true;
    }
  }, []);

  return (
    <Provider store={store}>
      <ChakraProvider theme={liahtutorTheme}>
        <ColorModeScript />
        <Suspense fallback={<Loading loadingText="..." />}>
          <AppRouter />
        </Suspense>
      </ChakraProvider>
    </Provider>
  );
};

export default App;
