import { useDisclosure, useToast } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { currentProgramActions } from "../../store/features/slices/currentTrainingProgram";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  currentSavedNotesKey,
  principalStorageKey,
  currentPrincipalTPKey,
  stepsConfig,
  principalTPKey,
  principalTokenKey,
} from "../../constants";
import {
  Course,
  Lesson,
  lessonGroup,
  LessonsState,
  Module,
  TrainingProgram,
} from "../../../types";
import imge1 from "../../assets/images/5534822.jpg";
import rididngAbike from "../../assets/images/riding-a-bicycle.png";
import darkOffice2 from "../../assets/images/darkOffice2.jpg";
import programCover from "../../assets/images/road.jpg";
import {
  hasSpecialCharacters,
  isBlob,
  stringToBoolean,
  urlToFile,
} from "../../utils";
import { AppDispatch } from "../../store/store";
import {
  saveFile,
  removeFile,
  getFile,
  getAllFiles,
  deleteDatabase,
  getFilesStartingWith,
} from "../../utils/db";
import axios from "axios";
import { toggleDashboardSidebar } from "../../store/features/slices/utitlitySlice";
import { useQueryParams } from "../../hooks";
import { programActions } from "../../store/features/slices/trainingPrograms";
import CourseSelection from "./../Programs/Create/AddModules/CourseSelection";
import debounce from "lodash.debounce";

export const useToggleSideBar = () => {
  const dispatch = useAppDispatch();

  const dashboardSidebarOpen = useAppSelector(
    (state) => state.utility.dashboardSidebarOpen
  );

  const toggleSidebar = () => {
    dispatch(toggleDashboardSidebar());
  };

  useEffect(() => {
    // Retrieve the stored value from localStorage and update Redux state if necessary
    const storedValue = localStorage.getItem("dashboardSidebarOpen");

    if (storedValue !== null) {
      const shouldOpen = storedValue === "true";
      if (shouldOpen !== dashboardSidebarOpen) {
        dispatch(toggleDashboardSidebar());
      }
    }
  }, [dispatch, dashboardSidebarOpen]);
  return { dashboardSidebarOpen, toggleSidebar };
};

export const useCreateTPLayout = () => {
  const currentTPString = localStorage.getItem(currentPrincipalTPKey);
  const [preferredSidebar, setPreferredSidebar] = useState<
    "collapse" | "expand"
  >("expand");

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isFormDirty, setIsFormDirty] = useState(true);
  const [shouldNavigate, setShouldNavigate] = useState(false);

  // Warn user when they try to leave the page
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isFormDirty) {
        event.preventDefault();
        event.returnValue = ""; // Show browser's default confirmation dialog
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isFormDirty]);

  // Warn user when they try to navigate away using React Router or other means
  useEffect(() => {
    const handleRouteChange = (event: Event) => {
      if (isFormDirty && !shouldNavigate) {
        event.preventDefault();
        onOpen();
      }
    };

    window.addEventListener("popstate", handleRouteChange);

    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, [isFormDirty, shouldNavigate, onOpen]);

  const handleFormChange = () => {
    setIsFormDirty(true);
  };

  const handleConfirmLeave = useCallback(() => {
    setShouldNavigate(true);
    onClose();
  }, [onClose]);

  //   ____
  const toggleSidebar = () => {
    setPreferredSidebar((state) => {
      if (state === "expand") {
        localStorage.setItem("preferedSidebar", "collapse");
        return "collapse";
      }
      localStorage.setItem("preferedSidebar", "expand");
      return "expand";
    });
  };
  const preferredSidebarLocal = localStorage.getItem("preferedSidebar") as
    | "collapse"
    | "expand"
    | undefined;

  useEffect(() => {
    setPreferredSidebar(preferredSidebarLocal ?? "expand");
  }, [preferredSidebarLocal]);

  const navigate = useNavigate();
  const location = useLocation();
  const currentPage = location.pathname.split("/").pop();
  const steps = useMemo(() => stepsConfig, []);

  const currentIndex = useMemo(() => {
    const elt = steps.findIndex((step) => step.path === currentPage);
    return elt > 0 ? elt : 0;
  }, [steps, currentPage]);

  const completedSteps = useMemo(() => {
    if (!currentTPString) {
      return steps.map(() => false);
    }

    const currentTP = JSON.parse(currentTPString);
    const trainingProgram = currentTP.payload || {};
    return steps.map((_, index) => {
      switch (index) {
        case 0:
          return !!trainingProgram.title;
        case 1:
          return (
            Array.isArray(trainingProgram.courses) &&
            trainingProgram.courses.length > 0
          );
        case 2:
          return (
            Array.isArray(trainingProgram.courses?.modules) &&
            trainingProgram.courses.modules.length > 0
          );
        case 3:
          return (
            Array.isArray(trainingProgram.courses?.modules?.lessons) &&
            trainingProgram.courses.modules.lessons.length > 0
          );
        case 4:
          return !!trainingProgram.introVideo;
        case 5:
          return !!trainingProgram.isPublished; // Assuming this is the condition for the last step
        default:
          return false;
      }
    });
  }, [currentTPString, steps]);

  function goPrevious() {
    navigate(-1);
  }

  return {
    goPrevious,
    preferredSidebar,
    completedSteps,
    currentIndex,
    steps,
    toggleSidebar,
    isOpen,
    onClose,
    handleConfirmLeave,
  };
};
interface Form01 {
  title: string;
  description: string;
  price: number;
  category: string;
  image: File | null | string;
  tags: (string | number)[];
  level: string;
  language: string;
}
export const useCreateTP01 = (intent: "create" | "update") => {
  const userInfoString = localStorage.getItem(principalStorageKey);
  const currentTPString = localStorage.getItem(currentPrincipalTPKey);
  const [error, setError] = useState("");
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectedLevel, setSelectedLevel] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormUpdated, setIsFormUpdated] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [form01, setForm01] = useState<Form01>({
    title: "",
    description: "",
    price: 0,
    category: "Select a category",
    image: null,
    tags: [],
    level: "",
    language: "",
  });

  const levels = ["Beginner", "Amateur", "Expert", "Advanced"];
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { programId } = useParams<{ programId: string }>();

  const loadTrainingProgramData = async () => {
    const localData = localStorage.getItem(principalTPKey);
    if (localData && programId) {
      const parsedData = JSON.parse(localData);

      console.log(parsedData);
      // Find the program matching the id from the URL
      const program = parsedData?.payload?.trainingProgram?.programs?.find(
        (p: TrainingProgram) => {
          return p._id === programId;
        }
      );

      if (program) {
        localStorage.setItem(
          currentPrincipalTPKey,
          JSON.stringify({ payload: { trainingProgram: program } })
        );
        // setProgram(program);
      }

      // setLoading(false);
      if (intent === "update" && program) {
        // const currentTP = JSON.parse(currentTPString);
        // const trainingProgram = currentTP?.payload?.trainingProgram;

        console.log("Training Program:", program); // Log to verify structure

        if (program) {
          const {
            title,
            description,
            price,
            category,
            tags,
            level,
            language,
            image,
          } = program;

          // Convert image URL to File object
          let imageFile = null;
          if (image) {
            const extension = image.match(/\.([0-9a-z]+)(?:[?#]|$)/i);
            if (extension) {
              const mimeType = `image/${extension[1]}`;
              imageFile = await urlToFile(
                image,
                `image.${extension[1]}`,
                mimeType
              );
            }
          }

          console.log("Inside training program found");

          // Update form state
          setForm01({
            title: title || "",
            description: description || "",
            price: price?.amount || 0,
            category: category || "Select a category",
            tags: tags || [],
            level: level || "",
            language: language || "",
            image: imageFile,
          });

          // Update dependent states
          setSelectedCategory(category);
          setSelectedLevel(level);
          setSelectedLanguage(language);
          setSelectedTags(tags);
        } else {
          console.warn("No trainingProgram found in payload.");
        }
      } else {
        console.warn("No currentTPString or intent is not update.");
      }
    }
  };
  // Fetch initial data if updating a program
  useEffect(() => {
    loadTrainingProgramData().catch((error) => {
      console.error("Error loading training program data:", error);
    });
  }, [intent, currentTPString]);

  // Update form01 state when dependent states (selected tags, level, etc.) change
  useEffect(() => {
    setForm01((prev) => ({
      ...prev,
      level: selectedLevel,
      tags: selectedTags,
      language: selectedLanguage,
      category: selectedCategory,
    }));
    setIsFormUpdated(true);
  }, [selectedLevel, selectedTags, selectedLanguage, selectedCategory]);

  const handleFileUpload = useCallback(async (file: File | undefined) => {
    if (file) {
      await saveFile("programImage", file);
      setForm01((prev) => ({ ...prev, image: file }));
    } else {
      await removeFile("programImage");
      setForm01((prev) => ({ ...prev, image: null }));
    }
  }, []);

  const handleChange = useCallback(
    (
      e: React.ChangeEvent<
        HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
      >
    ) => {
      const { name, value, type } = e.target;

      if (name === "price" && parseFloat(value) < 0) {
        setError("Price cannot be negative");
        return;
      }

      setForm01((prevState) => ({
        ...prevState,
        [name]: type === "number" ? parseFloat(value) : value,
      }));

      if (name === "title" && /[^a-zA-Z0-9 ]/.test(value)) {
        setError("Title cannot contain special characters");
      } else if (name === "title") {
        setError("");
      }
    },
    []
  );

  const updateOrCreateProgram = useCallback(
    async (action: any, successMessage: string, errorMessage: string) => {
      try {
        await dispatch(action).unwrap();
        toast({
          title: successMessage,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        navigate("/dashboard/programs/create-program/course");
        resetForm();
      } catch (error) {
        const errorMsg = axios.isAxiosError(error)
          ? error.response?.data?.message
          : errorMessage;
        setError(errorMsg || errorMessage);
        toast({
          title: "An error occurred",
          description: errorMsg,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    },
    [dispatch, toast, navigate]
  );

  const resetForm = useCallback(() => {
    setForm01({
      title: "",
      description: "",
      price: 0,
      category: "Select a category",
      image: null,
      tags: [],
      level: "",
      language: "",
    });
    setSelectedTags([]);
    setSelectedLevel("");
    setSelectedCategory("");
    setSelectedLanguage("");
    setIsFormUpdated(false);
  }, []);

  const onSubmit = async () => {
    setIsLoading(true);

    const userInfo = userInfoString ? JSON.parse(userInfoString) : null;
    const tutorId = userInfo?.payload?.user?._id;
    console.log(userInfo);
    if (!tutorId) {
      setIsLoading(false);
      return;
    }

    const actionPayload = {
      title: form01.title,
      description: form01.description,
      price: form01.price,
      category: form01.category,
      image: form01.image,
      tutor: tutorId,
      level: form01.level,
      tags: form01.tags,
      language: form01.language,
    };
    console.log(actionPayload);
    const action =
      intent === "update" && currentTPString
        ? currentProgramActions.updateTP({
            id: JSON.parse(currentTPString)?.payload?._id,
            trainingProgram: actionPayload,
          })
        : currentProgramActions.createTP01(actionPayload);

    const successMessage =
      intent === "update"
        ? "Program successfully updated."
        : "Program successfully created.";
    const errorMessage = "Failed to submit program. Please try again.";

    await updateOrCreateProgram(action, successMessage, errorMessage);
    setIsLoading(false);
  };

  const isFormComplete = () => {
    const { title, description, price, category, image, language, level } =
      form01;
    return (
      title &&
      description &&
      price >= 0 &&
      category &&
      image &&
      selectedTags.length &&
      selectedLanguage &&
      selectedLevel &&
      !error
    );
  };

  const handleRefresh = () => {
    setRefresh(true);
    const token = localStorage.getItem(principalTokenKey);
    if (token)
      dispatch(programActions.getMyTrainingPrograms(token))
        .unwrap()
        .then((res) => {
          loadTrainingProgramData().catch((error) => {
            console.error("Error loading training program data:", error);
          });

          // setRefresh((prev) => !prev);

          setRefresh(false);
        });
  };
  return {
    form01,
    handleChange,
    handleRefresh,
    refresh,
    selectedTags,
    setSelectedTags,
    onSubmit,
    error,
    selectedLevel,
    setSelectedLevel,
    selectedCategory,
    setSelectedCategory,
    selectedLanguage,
    setSelectedLanguage,
    levels,
    isLoading,
    handleFileUpload,
    isFormComplete,
  };
};

export const useCreateTP02 = () => {
  const currentTPString = localStorage.getItem(currentPrincipalTPKey);
  const [currentCourseList, setCurrentCourseList] = useState<Course[]>();
  const [error, setError] = useState("");
  const [errorr, setErrorr] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [form02, setForm02] = useState({
    title: "",
    description: "",
    image: null as File | null | string,
  });
  const [tempCourseImg, setTempCourseImg] = useState<Blob>();

  async function getTempImage(title: string) {
    const tempCourseImg = await getFile(`courseImage_${title.trim()}`);
    if (tempCourseImg) {
      // The image was found, handle it accordingly
      setTempCourseImg(tempCourseImg);
      // console.log('Image found:', tempCourseImg);
    } else {
      // The image was not found, handle the absence of the image
      console.warn("No image found for the specified title");
    }
    // Rest of your code
  }

  const toast = useToast();
  const dispatch = useAppDispatch();

  const handleFileUpload = async (file: File | undefined) => {
    if (file) {
      setForm02((prev) => ({
        ...prev,
        image: file,
      }));
      console.log(file);
      // setImageUrl(URL.createObjectURL(file));
    } else {
      await removeFile("courseImage");
      setForm02((prev) => ({
        ...prev,
        image: null,
      }));
    }
  };

  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const { name, value, type } = e.target;
    if (type === "number") {
      setForm02((prevState) => ({
        ...prevState,
        [name]: parseFloat(value),
      }));
    } else {
      setForm02((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      if (name === "title" && hasSpecialCharacters(value)) {
        setError("Please, the Title can't have special characters");
      } else if (name === "title") {
        setError("");
      }
    }
  };

  useEffect(() => {
    if (currentTPString) {
      const currentTP = JSON.parse(currentTPString);
      const currentCourseList = currentTP.payload?.courses;
      setCurrentCourseList(currentCourseList);
    }
  }, [currentTPString]);
  const onSubmit = async () => {
    if (currentTPString) {
      const currentTP = JSON.parse(currentTPString);
      const currentTPId = currentTP.payload?._id;
      console.log(currentTPId);
      const currentCourseList = currentTP.payload?.courses;
      setCurrentCourseList(currentCourseList);
      setIsLoading(true);
      try {
        if (typeof form02.image === "object" && form02.image) {
          await saveFile(`courseImage_${form02.title.trim()}`, form02.image);
        }
        console.log(form02);
        await dispatch(
          currentProgramActions.createTP02({
            title: form02.title,
            description: form02.description,
            image: form02.image,
            currentTPId: currentTPId,
          })
        )
          .unwrap()
          .then(() => {
            setErrorr("");
            toast({
              title: "Course created.",
              description: "Your Course has been successfully added.",
              status: "success",
              duration: 9000,
              isClosable: true,
            });

            setForm02({
              title: "",
              description: "",
              image: null,
            });
          });
      } catch (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "Something went wrong, please try again.";
        setErrorr(errorMessage);
        toast({
          title: "An error occurred.",
          description: errorr,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
      setIsLoading(false);
    }
  };

  const goNext = () => {
    if (currentCourseList && currentCourseList.length > 0)
      navigate("/dashboard/programs/create-program/courses-module");
  };
  const isFormComplete = () => {
    const { title, description, image } = form02;
    return title !== "" && description !== "" && image !== null && error === "";
  };
  return {
    form02,
    currentCourseList,
    handleChange,
    onSubmit,
    error,
    isLoading,
    handleFileUpload,
    goNext,
    isFormComplete,
    tempCourseImg,
    getTempImage,
  };
};

export const useAddModule = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const currentTPString = localStorage.getItem(currentPrincipalTPKey);
  const [currentCourseList, setCurrentCourseList] = useState<Course[]>();
  const [currentModuleList, setCurrentModuleList] = useState<Module[]>();
  const [selectedCourse, setSelectedCourse] = useState<Course | null>();
  const [errorr, setErrorr] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [courseModuleData, setCourseModuleData] = useState<{
    [courseId: string]: { title: string; description: string };
  }>({});

  const [courseImages, setCourseImages] = useState<{ [key: string]: string }>(
    {}
  );

  const isBlob = (value: any): value is Blob => value instanceof Blob;

  useEffect(() => {
    if (!currentCourseList) return;

    const fetchImages = async () => {
      const images = await Promise.all(
        currentCourseList.map(async (course) => {
          if (!course._id) {
            console.error(`Course ${course.title} is missing an _id`);
            return null;
          }
          const tempCourseImg = await getFile(
            `courseImage_${course.title.trim()}`
          );
          return {
            [course._id]: isBlob(tempCourseImg)
              ? URL.createObjectURL(tempCourseImg)
              : "",
          };
        })
      );

      const imagesMap = images.reduce<{ [key: string]: string }>(
        (acc, image) => {
          if (image) {
            return { ...acc, ...image };
          }
          return acc;
        },
        {}
      );

      setCourseImages(imagesMap);
    };

    fetchImages().then((_) => {
      return _;
    });
  }, [currentCourseList]);
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >,
    courseId: string
  ) => {
    const { name, value } = e.target;

    setCourseModuleData((prevData) => ({
      ...prevData,
      [courseId]: {
        ...prevData[courseId],
        [name]: value,
      },
    }));

    if (name === "title" && hasSpecialCharacters(value)) {
      setError("Please, the Title can't have special characters");
    } else if (name === "title") {
      setError("");
    }
  };

  useEffect(() => {
    if (currentTPString) {
      const currentTP = JSON.parse(currentTPString);
      const currentCourseList = currentTP.payload?.courses;
      setCurrentCourseList(currentCourseList);
    }
  }, [currentTPString]);
  useEffect(() => {
    if (currentTPString && selectedCourse) {
      const currentTP = JSON.parse(currentTPString);
      const currentCourseList = currentTP.payload?.courses;
      setCurrentCourseList(currentCourseList);
      const currentModuleList = currentTP.payload?.courses.find(
        (course: Course) => course._id === selectedCourse._id
      )?.modules;
      setCurrentModuleList(currentModuleList);
    }
  }, [currentTPString, selectedCourse]);
  const onSubmit = async () => {
    if (currentTPString && selectedCourse?._id) {
      try {
        // Parse the currentTPString safely
        const currentTP = JSON.parse(currentTPString);
        const currentTPId = currentTP.payload?._id;
        const currentCourseList = currentTP.payload?.courses;

        // Update course and module lists
        setCurrentCourseList(currentCourseList);
        const currentModuleList = currentCourseList?.find(
          (course: Course) => course._id === selectedCourse._id
        )?.modules;
        setCurrentModuleList(currentModuleList);

        setIsLoading(true);

        // Retrieve the selected module data
        const selectedModuleData = courseModuleData[selectedCourse._id];

        // Dispatch the addModuleThunk action
        await dispatch(
          currentProgramActions.addModuleThunk({
            data: {
              title: selectedModuleData.title,
              description: selectedModuleData.description,
            },
            ids: {
              id: currentTPId,
              courseId: selectedCourse._id,
            },
          })
        ).unwrap();

        // On successful dispatch
        setError(""); // Fixed typo
        toast({
          title: "Module created.",
          description: "Your Course has been successfully added.",
          status: "success",
          duration: 9000,
          isClosable: true,
        });

        // Reset the form fields for the selected course
        if (selectedCourse?._id) {
          setCourseModuleData((prevModuleData) => ({
            ...prevModuleData,
            [selectedCourse._id as string]: {
              title: "",
              description: "",
            },
          }));
        }
      } catch (error) {
        // Handle errors gracefully
        const errorMessage =
          error instanceof Error
            ? error.message
            : "Something went wrong, please try again.";
        setError(errorMessage);
        toast({
          title: "An error occurred.",
          description: errorMessage, // Fixed typo
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const isFormComplete = () => {
    if (selectedCourse?._id) {
      const selectedModuleData = courseModuleData[selectedCourse._id] || {};
      const { title = "", description = "" } = selectedModuleData;
      return title.trim() !== "" && description.trim() !== "" && error === "";
    }
  };
  const goNext = () => {
    if (currentModuleList && currentModuleList.length > 0)
      navigate("/dashboard/programs/create-program/courses-lesson");
  };
  return {
    courseModule: courseModuleData,
    courseImages,
    currentCourseList,
    currentModuleList,
    selectedCourse,
    error,
    setSelectedCourse,
    handleChange,
    goNext,
    onSubmit,
    isFormComplete,
    isLoading,
  };
};

export const useAddLesson = () => {
  const toast = useToast();
  const dispatch = useAppDispatch();
  const currentTPString = localStorage.getItem(currentPrincipalTPKey);
  const savedLocalNotes = localStorage.getItem(currentSavedNotesKey) || "";
  const [currentCourseList, setCurrentCourseList] = useState<Course[]>();
  const [currentModuleList, setCurrentModuleList] = useState<Module[]>();
  const [currentlessonList, setCurrentLessonList] = useState<Lesson[]>();
  const [selectedCourse, setSelectedCourse] = useState<Course | null>();
  const [selectedModule, setSelectedModule] = useState<Module | null>();
  const [error, setError] = useState("");
  const [errorr, setErrorr] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const textBoxRef = useRef<HTMLDivElement>(null); // Ref for notes textarea
  const [lEsSoN, setLeSsOn] = useState<Lesson | null>();
  const [moduleLesson, setModuleLesson] = useState({
    title: lEsSoN?.title || "",

    video: lEsSoN?.video || (null as File | null),
    notes: lEsSoN?.notes || "",
    attachments: lEsSoN?.attachments || (undefined as File[] | undefined),
  });
  useEffect(() => {
    if (lEsSoN)
      setModuleLesson({
        title: lEsSoN.title,
        video: lEsSoN.video,
        notes: lEsSoN.notes as string,
        attachments: lEsSoN?.attachments,
      });
  }, [lEsSoN]);
  useEffect(() => {
    localStorage.setItem("_SelectedModule", selectedModule?._id || ""); // Save selected modules id
  }, [selectedModule]);
  useEffect(() => {
    localStorage.setItem("_SelectedLessonId", lEsSoN?._id || ""); // Save selected lesson id
  }, [lEsSoN?._id, selectedModule]);

  const handleTextareaChange = (
    value: string,
    name: string,
    selectedModuleID: string | undefined
  ) => {
    // Log to verify the received module ID
    console.log("Module ID:-> ", selectedModuleID ?? "No Module ID provided.");

    setModuleLesson((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Handle special characters check for the title
    if (name === "title" && hasSpecialCharacters(value)) {
      setError("Please, the Title can't have special characters");
    } else if (name === "title") {
      setError("");
    }
  };

  const handleFileUpload = (file: File | undefined) => {
    setModuleLesson((prev) => ({
      ...prev,
      video: file || null,
    }));
  };

  const handleAttachmentUpload = (newFiles: File[]) => {
    setModuleLesson((prev) => {
      // Combine the previous attachments with new files
      const updatedAttachments = prev.attachments
        ? [...prev.attachments, ...newFiles]
        : newFiles;

      return {
        ...prev,
        attachments: updatedAttachments,
      };
    });
  };

  useEffect(() => {
    const selectedModuleID = localStorage.getItem("_SelectedModule") || "";
    //
    if (currentTPString && selectedCourse) {
      const currentTP = JSON.parse(currentTPString);
      console.log(selectedModuleID);
      const currentModuleList = currentTP.payload?.courses.find(
        (course: Course) => course._id === selectedCourse._id
      )?.modules;
      const particularModule = currentModuleList.find(
        (m: Module) => m._id === selectedModuleID
      );
      console.log(particularModule);

      const lEsSoN = particularModule.lessons[0];
      console.log(lEsSoN);
      setLeSsOn(lEsSoN);
    }
  }, [currentTPString, selectedModule]);
  useEffect(() => {
    if (currentTPString) {
      const currentTP = JSON.parse(currentTPString);
      const currentCourseList = currentTP.payload?.courses;
      setCurrentCourseList(currentCourseList);
    }
  }, [currentTPString]);
  useEffect(() => {
    if (currentTPString && selectedCourse) {
      const currentTP = JSON.parse(currentTPString);

      const currentModuleList = currentTP.payload?.courses.find(
        (course: Course) => course._id === selectedCourse._id
      )?.modules;
      setCurrentModuleList(currentModuleList);
    }
  }, [currentTPString, selectedCourse]);
  const q = useQueryParams();

  useEffect(() => {
    if (!currentTPString) return; // Early return if currentTPString is not defined

    try {
      const currentTP = JSON.parse(currentTPString);
      const courseId = q["course"];
      const moduleId = q["module"];

      if (courseId && moduleId) {
        console.log("In it more");

        const course = currentTP.payload?.courses.find(
          (course: Course) => course._id === courseId
        );
        console.log(course);

        if (course) {
          const module = course.modules.find(
            (module: Module) => module._id === moduleId
          );
          if (module) {
            setCurrentLessonList(module.lessons || []);
          } else {
            setCurrentLessonList([]);
          }
        } else {
          setCurrentLessonList([]);
        }
      }
    } catch (error) {
      console.error("Failed to parse currentTPString:", error);
    }
  }, [currentTPString, q["course"], q["module"]]);

  const onSubmitLessons = async (lessons: LessonsState) => {
    if (!currentTPString) {
      toast({
        title: "Error",
        description: "Course and Module must be selected",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }
    const newLesson = {
      title: moduleLesson.title,
      notes: savedLocalNotes || moduleLesson.notes,
      video: moduleLesson.video,
      attachment: moduleLesson.attachments,
    };
    if (typeof moduleLesson.video === "object" && moduleLesson.video) {
      await saveFile(`lessonVideo_${moduleLesson.title}`, moduleLesson.video);
    }
    console.log("lesson", newLesson);
    const currentTP = JSON.parse(currentTPString);
    const currentTPId = currentTP.payload?._id;
    // const currentCourseList = currentTP.payload?.courses
    // setCurrentCourseList(currentCourseList)
    // const currentModuleList = currentTP.payload?.courses.find((course: Course) => course._id === selectedCourse._id)?.modules
    // setCurrentModuleList(currentModuleList)
    setIsLoading(true);
    try {
      await dispatch(
        currentProgramActions.addLessonThunk({
          data: lessons,
          currentTPId,
        })
      )
        .unwrap()
        .then(() => {
          setError("");
          toast({
            title: "lesson created.",
            description: "Your Lesson has been successfully added.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
          setModuleLesson({
            title: "",
            video: null as File | null,
            notes: "",
            attachments: undefined as File[] | undefined,
          });
        });
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : "Something went wrong, please try again.";
      setErrorr(errorMessage);
      toast({
        title: "Error in creating Lesson",
        description: errorr,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
  const isFormComplete = () => {
    const { title, video } = moduleLesson;
    return title !== "" && video !== "" && error === "";
  };
  const goNext = () => {
    if (currentModuleList && currentModuleList.length > 0)
      navigate("/dashboard/programs/create-program/intro-video");
  };
  return {
    isFormComplete,
    moduleLesson,
    handleFileUpload,
    currentCourseList,
    currentModuleList,
    currentlessonList,
    selectedCourse,
    setSelectedCourse,
    selectedModule,
    setSelectedModule,
    handleTextareaChange,
    handleAttachmentUpload,
    goNext,
    onSubmitLessons,
    error,
    isLoading,
    textBoxRef,
    lEsSoN,
  };
};

export const useSavePublish = () => {
  const [error, setError] = useState("");
  const [programCover, setProgramCover] = useState("");
  const [isPublished, setIsPublished] = useState(false);
  const [files, setFiles] = useState<{ id: string; file: Blob }[]>([]);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const filesFromDB = await getFilesStartingWith("introVideo");
        console.log(filesFromDB);
        setFiles(filesFromDB);
      } catch (error) {
        console.error("Error fetching files from IndexedDB", error);
      }
    };

    fetchFiles();
  }, []);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentTPString = localStorage.getItem(currentPrincipalTPKey);
  const [programLink, setProgramLink] = useState(
    "https://liahstudy.online/programs/"
  );
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [hoveredIndex, setHoveredIndex] = useState<number>(-1); // State to track the index of the hovered card
  const [hoveredPosition, setHoveredPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 }); // State to track the position of the hover
  const widths = [1, 3 / 4, 1 / 2, 1 / 4]; // Widths for each card based on the rule
  useEffect(() => {
    // const currentTPName: string = currentTP.payload.trainingProgram.name
    const fetchProgramCover = async () => {
      const programImage = await getFile(`programImage`);
      const imageUrl = isBlob(programImage)
        ? URL.createObjectURL(programImage)
        : "";
      setProgramCover(imageUrl);
    };

    fetchProgramCover();
  }, []);
  const images = [programCover, rididngAbike, darkOffice2, imge1];
  const uploadMessages = [
    { id: 1, message: "Initializing upload process..." },
    { id: 2, message: "Connecting to server..." },
    { id: 3, message: "Checking files integrity..." },
    { id: 4, message: "Encrypting files for secure transfer..." },
    { id: 5, message: "Uploading files to the server..." },
    { id: 6, message: "Files uploaded successfully. Processing data..." },
    { id: 7, message: "Analyzing file contents..." },
    { id: 8, message: "Organizing the files..." },
    { id: 9, message: "Preparing data visualization..." },
    { id: 10, message: "Upload process completed" },
  ];

  const handleMouseEnter = (
    index: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setHoveredIndex(index);
    const rect = event.currentTarget.getBoundingClientRect();
    setHoveredPosition({
      x: event.clientX - rect.left,
      y: event.clientY - rect.top,
    });
  };

  const handleMouseLeave = () => {
    setHoveredIndex(-1);
  };

  const getRotationAngle = (index: number): number => {
    const cardWidth = 48 * widths[index]; // Width of the card in em
    const cardHeight = 24; // Height of the card in em
    const hoverX = hoveredPosition.x;
    const cardX = cardWidth / 2;

    if (hoverX < cardX) {
      return -2; // Left side of the card, rotate -3 degrees
    } else {
      return 2; // Right side of the card, rotate 3 degrees
    }
  };

  const handlePublishToWorld = async () => {
    if (isLoading) return;

    if (currentTPString) {
      setIsLoading(true);
      const currentTP = JSON.parse(currentTPString);
      const currentTPId = currentTP.payload?._id;
      const currentProgramTitle = currentTP.payload?.title;
      if (!currentTPId) {
        setError("Training Program ID not found.");
        toast({
          title: "Error",
          description: "Training Program ID not found.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
        setIsLoading(false);
        return;
      }

      try {
        await dispatch(currentProgramActions.publishTPThunk(currentTPId))
          .unwrap()
          .then(() => {
            setError("");
            deleteDatabase();
            setIsPublished(true);
            if (currentProgramTitle) {
              setProgramLink(
                `https://liahstudy.online/programs/${currentProgramTitle.replace(
                  " ",
                  "-"
                )}`
              );
            }
            toast({
              title: "CONGRATS",
              description: "Your Training Program is live",
              status: "success",
              duration: 9000,
              isClosable: true,
            });
          });
      } catch (error) {
        const errorMessage =
          error instanceof Error
            ? error.message
            : "Something went wrong, please try again.";
        setError(errorMessage);
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setError("Training Program data not found.");
      toast({
        title: "Error",
        description: "Training Program data not found.",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleSave = () => {
    if (isLoading) return;
    setIsLoading(true);

    // Simulate a save operation with a 3-second delay
    setTimeout(() => {
      navigate("/dashboard");
      setIsLoading(false);
    }, 3000);
  };

  const handleShareClick = (platform: string) => {
    let shareUrl = "";
    switch (platform) {
      case "facebook":
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${programLink}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?url=${programLink}`;
        break;
      case "twitter":
        shareUrl = `https://twitter.com/share?url=${programLink}&text=Check%20out%20this%20training%20program!`;
        break;
      case "instagram":
        toast({
          title: "Instagram does not support direct sharing of links.",
          status: "info",
          duration: 3000,
          isClosable: true,
        });
        return;
      case "whatsapp":
        shareUrl = `https://wa.me/?text=Check%20out%20this%20training%20program!%20${programLink}`;
        break;
      default:
        return;
    }
    window.open(shareUrl, "_blank");
  };
  return {
    isPublished,
    handleShareClick,
    programLink,
    widths,
    images,
    uploadMessages,
    hoveredIndex,
    hoveredPosition,
    isLoading,
    handleSave,
    handlePublishToWorld,
    handleMouseEnter,
    handleMouseLeave,
    getRotationAngle,
  };
};

export const useEditTrainingProgram = () => {
  const { programId } = useParams<{ programId: string }>();
  const [program, setProgram] = useState<TrainingProgram | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [courseList, setCourseList] = useState<Course[] | null>(null);

  useEffect(() => {
    const localData = localStorage.getItem(principalTPKey);
    if (localData && programId) {
      const parsedData = JSON.parse(localData);

      console.log(parsedData);
      // Find the program matching the id from the URL
      const program = parsedData?.payload?.trainingProgram?.programs?.find(
        (p: TrainingProgram) => {
          return p._id === programId;
        }
      );

      if (program) {
        localStorage.setItem(
          currentPrincipalTPKey,
          JSON.stringify({ payload: { trainingProgram: program } })
        );
        setProgram(program);

        const courseList = program.courses;
        if (courseList) setCourseList(courseList);
      }
    }
    setLoading(false);
  }, [programId]);

  const updateProgram = (updatedProgram: Partial<TrainingProgram> | null) => {
    if (!program) return;

    const updatedData = {
      ...program,
      ...updatedProgram,
    };

    setProgram(updatedData);

    // Simulate saving to local storage (you can replace this with an API call)
    const localData = localStorage.getItem("payload");
    if (localData && programId) {
      const parsedData = JSON.parse(localData);
      const updatedPrograms = parsedData.trainingProgram.programs.map(
        (p: TrainingProgram) => (p._id === programId ? updatedData : p)
      );
      parsedData.trainingProgram.programs = updatedPrograms;
      localStorage.setItem("payload", JSON.stringify(parsedData));
    }
  };
  return {
    program,
    loading,
    updateProgram,
    courseList,
  };
};
