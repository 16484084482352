import { motion } from "framer-motion";
import { forwardRef } from "react";
import { Accordion, AccordionProps, Box } from "@chakra-ui/react";


export const MotionBox = motion(
    forwardRef<HTMLDivElement, React.ComponentProps<typeof Box>>((props, ref) => (
      <Box ref={ref} {...props} />
    ))
  );

  export const MotionAccordion = motion(
    forwardRef<HTMLDivElement, AccordionProps>((props, ref) => (
      <Accordion ref={ref} {...props} />
    ))
  );