import axios from "axios";
import {
  currentBackend,
  customHeaderKey,
  customHeaderSecret,
  principalRefreshTokenKey,
  principalTokenKey,
} from "../constants";
import eventBus from "../utils/eventbus";
import { WarningIcon } from "@chakra-ui/icons";
import { hashValue } from "../utils";

let hash: string | null = null;
async function initialize() {
  const secret =
    process.env.REACT_APP_CUSTOM_HEADER_SECRET ?? customHeaderSecret;
  hash = await hashValue(secret);
}
initialize();

// Flag to prevent multiple refresh requests simultaneously
let isRefreshing = false;
let failedQueue: any[] = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

// Create an Axios instance with the base URL
export const backend = axios.create({
  baseURL: currentBackend,
});

backend.interceptors.request.use((req) => {
  req.headers[customHeaderKey] = hash;
  req.headers["Authorization"] =
    "Bearer " + localStorage.getItem(principalTokenKey);
  return req;
});

backend.interceptors.response.use(
  (res) => {
    const token = res.data.token as string;
    if (token) {
      localStorage.setItem(principalTokenKey, token);
    }
    return res;
  },
  async (error) => {
    const originalRequest = error.config;
    const token = localStorage.getItem(principalTokenKey);
    // Report network connection problems only if the user is authenticated
    if (axios.isAxiosError(error) && token) {
      console.error("Axios error:", error.message);
      if (error.message === "Network Error") {
        eventBus.emit("showErrorModal", {
          title: "Network Connection Error",
          message:
            "We are unable to reach the server at the moment. Please check your internet connection and try again.",
          icon: WarningIcon,
          buttonText: "Retry",
          buttonAction: () => window.location.reload(),
        });
      }

      if (error.response) {
        switch (error.response.status) {
          case 400:
            console.log(error.response.data?.message);
            // eventBus.emit('showErrorModal', {
            //   title: 'Bad Request',
            //   message: 'There was an issue with the request sent to the server. Please check and try again.',
            //   icon: WarningIcon,
            //   buttonText: 'Retry',
            //   // buttonAction: retryFunction, // Assume retryFunction is defined
            // });
            break;
          case 401:
            // Token expiration or unauthorized access
            if (!originalRequest._retry) {
              if (isRefreshing) {
                // If a token refresh is already in progress, queue the failed request
                return new Promise((resolve, reject) => {
                  failedQueue.push({ resolve, reject });
                })
                  .then((token) => {
                    originalRequest.headers[
                      "Authorization"
                    ] = `Bearer ${token}`;
                    return axios(originalRequest);
                  })
                  .catch((err) => {
                    return Promise.reject(err);
                  });
              }

              originalRequest._retry = true;
              isRefreshing = true;

              const refreshToken = localStorage.getItem(
                principalRefreshTokenKey
              ); // Get the refresh token

              if (refreshToken) {
                console.log(refreshToken);

                try {
                  const response = await axios.post("/auth/refresh-token", {
                    token: refreshToken,
                  });

                  const newAccessToken = response.data.accessToken;
                  const newRefreshToken = response.data.refreshToken;

                  // Store the new tokens in localStorage
                  // localStorage.setItem(principalTokenKey, newAccessToken);
                  localStorage.setItem(
                    principalRefreshTokenKey,
                    newRefreshToken
                  );

                  // Process the queue of failed requests with the new access token
                  processQueue(null, newAccessToken);

                  // Retry the original request with the new access token
                  originalRequest.headers[
                    "Authorization"
                  ] = `Bearer ${newAccessToken}`;
                  return axios(originalRequest);
                } catch (err) {
                  // If the refresh token fails (e.g., expired), reject the queue and force a logout
                  processQueue(err, null);
                  // localStorage.removeItem(principalTokenKey);
                  // localStorage.removeItem(principalRefreshTokenKey);
                  // Optionally redirect to login page
                  const currentPath = window.location.pathname;
                  const dashboardPath = "/dashboard";
                  if (currentPath.startsWith(dashboardPath)) {
                    // Check if the user is on the dashboard route
                    // localStorage.clear();
                    // eventBus.emit("showErrorModal", {
                    //   title: "Session Expired",
                    //   message:
                    //     "Your session has expired. Please log in again to continue.",
                    //   icon: WarningIcon,
                    //   buttonText: "Log In",
                    //   // buttonLink: "/signin",
                    // });
                  }
                  return Promise.reject(err);
                } finally {
                  isRefreshing = false;
                }
              } else {
                // No refresh token, force logout
                const currentPath = window.location.pathname; // Get current path
                const dashboardPath = "/dashboard"; // Define the dashboard route path
                if (currentPath.startsWith(dashboardPath)) {
                  // Check if the user is on the dashboard route
                  // localStorage.clear();
                  // eventBus.emit("showErrorModal", {
                  //   title: "Session Expired",
                  //   message:
                  //     "Your session has expired. Please log in again to continue.",
                  //   icon: WarningIcon,
                  //   buttonText: "Log In",
                  // buttonLink: "/signin",
                  // });
                }
                return Promise.reject(error);
              }
            }

            break;
          case 403:
            eventBus.emit("showErrorModal", {
              title: "Forbidden",
              message: "You do not have permission to access this resource.",
              icon: WarningIcon,
              buttonText: "Go Back",
              buttonAction: () => window.history.back(),
            });
            break;
          case 404:
            eventBus.emit("showErrorModal", {
              title: "Not Found",
              message:
                "The requested resource could not be found on the server.",
              icon: WarningIcon,
              buttonText: "Return to Home",
              buttonLink: "/",
            });
            break;
          case 500:
            console.log(error.response.data?.message);
            // eventBus.emit('showErrorModal', {
            //   title: 'Server Error',
            //   message: error.response.data?.message,
            //   icon: WarningIcon,
            //   buttonText: 'Retry',
            //   // buttonAction: retryFunction, // Assume retryFunction is defined
            // });
            break;
          case 503:
            eventBus.emit("showErrorModal", {
              title: "Service Unavailable",
              message:
                "The service is currently unavailable. Please try again later.",
              icon: WarningIcon,
              buttonText: "Retry",
              // buttonAction: retryFunction, // Assume retryFunction is defined
            });
            break;
          default:
            eventBus.emit("showErrorModal", {
              title: "Unknown Error",
              message: "An unexpected error occurred. Please try again.",
              icon: WarningIcon,
              buttonText: "Close",
              buttonAction: () => console.log("Closed"),
            });
            break;
        }
      }
    } else {
      console.error("Unexpected error:", error);
    }
    return Promise.reject(error);
  }
);

// API endpoints configuration
export const endpoints = {
  global: {
    listCategories: "category?list=all",
    listTags: "/tags",
    subscribeToNewsLetter: "/newsletter",
  },
  auth: {
    signin: "/auth/tutor/signin",
    recoverPassword: "/auth/tutor/forgot-password",
    signup: "/auth/tutor/signup",
    verifyOtp: "/auth/tutor/verify-otp",
    uploadPhoto: "/auth/tutor/upload-photo",
    uploadSocial: "/auth/tutor/upload-social",
    updateUserInfo: "/auth/tutor/update-user",
    uploadCertification: "/auth/tutor/upload-certification",
    uploadBio: "/auth/tutor/add-bio",
    uploadReview: "/auth/tutor/upload-review",
    forgetPassword: "/auth/tutor/forget-password",
    resetPassword: "/auth/tutor/reset-password",
    root: "/auth/tutor",
    // logout: "/auth/logout",
    // googleLogin: "/auth/login/social/google",
    // googleRegister: "/auth/register/social/google",
  },
  tutors: {
    me: "/tutors/me",
    root: "/tutors",
    getTutorsForProgram: "/tutors/multiple",
    uploadChunk: (programId: string) =>
      `/training-program/${programId}/upload-chunk`, // Upload chunk
    initializeUpload: (programId: string) =>
      `/training-program/${programId}/init-upload`,
    finalizeUpload: (programId: string) =>
      `/training-program/${programId}/finalize-upload`,
    // Training Program Endpoints
    getProgram: "/tutors/training-programs",
    addTheIntroVid: (id: string) => `/training-program/${id}/intro-video`, // Add the Intro Video into a specific training program
    getCategories: "/tutors/training-programs",
    createProgram: "/training-program", // Create a training program
    getATrainingProgram: (id: string) => `/training-program/${id}`, // Read a specific training program
    updateTrainingProgram: (id: string) => `/training-program/${id}`, // Update a specific training program
    deleteTrainingProgram: (id: string) => `/training-program/${id}`, // Delete a specific training program

    // Course Endpoints
    addCourse: (id: string) => `/training-program/${id}/courses`, // Create a course in a training program
    getACourse: (id: string, courseId: string) =>
      `/training-program/${id}/courses/${courseId}`, // Read a specific course
    updateCourse: (id: string, courseId: string) =>
      `/training-program/${id}/courses/${courseId}`, // Update a specific course
    deleteCourse: (id: string, courseId: string) =>
      `/training-program/${id}/courses/${courseId}`, // Delete a specific course

    // Module Endpoints
    addModules: (id: string, courseId: string) =>
      `/training-program/${id}/courses/${courseId}/modules`, // Create modules in a course
    getAModule: (id: string, courseId: string, moduleId: string) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}`, // Read a specific module
    updateModule: (id: string, courseId: string, moduleId: string) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}`, // Update a specific module
    deleteModule: (id: string, courseId: string, moduleId: string) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}`, // Delete a specific module

    // Lesson Endpoints
    addLessons: (id: string, courseId: string, moduleId: string) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}/lessons`, // Create lessons in a module
    getLessons: (id: string, courseId: string, moduleId: string) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}/lessons`, // Create lessons in a module
    getALesson: (
      id: string,
      courseId: string,
      moduleId: string,
      lessonId: string
    ) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}`, // Read a specific lesson
    updateLesson: (
      id: string,
      courseId: string,
      moduleId: string,
      lessonId: string
    ) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}`, // Update a specific lesson
    deleteLesson: (
      id: string,
      courseId: string,
      moduleId: string,
      lessonId: string
    ) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}`, // Delete a specific lesson

    // Quiz Endpoints
    addQuizzes: (
      id: string,
      courseId: string,
      moduleId: string,
      lessonId: string
    ) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/quizzes`, // Create quizzes in a lesson
    getAQuiz: (
      id: string,
      courseId: string,
      moduleId: string,
      lessonId: string,
      quizId: string
    ) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/quizzes/${quizId}`, // Read a specific quiz
    updateQuiz: (
      id: string,
      courseId: string,
      moduleId: string,
      lessonId: string,
      quizId: string
    ) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/quizzes/${quizId}`, // Update a specific quiz
    deleteQuiz: (
      id: string,
      courseId: string,
      moduleId: string,
      lessonId: string,
      quizId: string
    ) =>
      `/training-program/${id}/courses/${courseId}/modules/${moduleId}/lessons/${lessonId}/quizzes/${quizId}`, // Delete a specific quiz

    // Publishing
    publishTrainingProgram: (id: string) => `training-program/${id}/publish`,
  },
};
