import { ChangeEvent, useEffect, useRef, useState } from "react";
import { AuthIntent, ErrorResponse } from "../types";
import * as Yup from "yup";
import { useFormik } from "formik";
import { Toast, ToastMessage } from "primereact/toast";

import zxcvbn from "zxcvbn";
import { useAppDispatch } from "../app/hooks";
import { userActions } from "../store/features/slices/authSlice";
import { useQuery, useToast } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { principalStorageKey, principalTokenKey } from "../constants";
import { useTranslation } from "react-i18next";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { useHandleOtpInput, useOnChangeQueryParams } from "./common.hooks";
import { useQueryParams } from ".";

export const useSignupLogin = (intent: AuthIntent) => {
  const { t } = useTranslation();
  const [msg, setMsg] = useState<ToastMessage | null>(null);
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthMessages, setPasswordStrengthMessages] = useState<
    string[]
  >([]);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGoogleAuthLoading, setIsGoogleAuthLoading] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const [phoneInputActive, setPhoneInputActive] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const q = useQueryParams();
  const onChangeQueryParams = useOnChangeQueryParams();
  const passwordStrengthMessagesFixed = [
    "Very weak",
    "Weak",
    "Fair",
    "Strong",
    "Very strong",
  ];

  // Handle the phoneInputActiveState
  const handleFocus = () => {
    setPhoneInputActive(true);
  };
  const handleBlur = () => {
    setPhoneInputActive(false);
  };
  // Toggle password visibility
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const togglePasswordConfirmVisibility = () =>
    setShowPasswordConfirm(!showPasswordConfirm);

  // Form validation schema using Yup
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
    ...(intent === "signup" && {
      passwordConfirm: Yup.string()
        .oneOf([Yup.ref("password"), ""], "Passwords must match")
        .required("Please confirm your password"),
      firstName: Yup.string().required("First name is required"),
      lastName: Yup.string().required("Last name is required"),
      tel: Yup.string().required("Phone number is required"),
    }),
  });

  // Formik setup
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",
      tel: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setMsg(null);
      const { email, password, passwordConfirm, firstName, lastName, tel } =
        values;

      setIsLoading(true);
      try {
        if (intent === "signin") {
          await dispatch(
            userActions.signinUser({
              email: values.email,
              password: values.password,
            })
          )
            .unwrap()
            .then(() => {
              setMsg({
                severity: "success",
                summary: "Success",
                detail: "You have successfully signed in",
                life: 3000,
              });
              const next = q.next ?? "/dashboard";
              navigate(next);
            });
        } else if (intent === "signup") {
          if (passwordStrength < 3) {
            setMsg({
              severity: "error",
              summary: "Error",
              detail: "Password is too weak",
              life: 3000,
            });
            setIsLoading(false);
            return;
          }
          await dispatch(userActions.signUpUser(values))
            .unwrap()
            .then(() => {
              setMsg({
                severity: "success",
                summary: "Success",
                detail: "You have successfully signed up",
                life: 3000,
              });
              navigate("/auth/verify-otp");
            });
        }
      } catch (err: any) {
        onChangeQueryParams((param) => {
          if (err.error) param.set("error", err.error);
          if (err.message) param.set("error", err.message);
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const googleSignIn = async () => {
    try {
      setIsGoogleAuthLoading(true);

      // Determine the Google Auth URL based on environment
      const googleAuthUrl =
        process.env.NODE_ENV !== "production"
          ? "http://localhost:8000/auth/tutor/google"
          : process.env.REACT_APP_BASE_URL
          ? `${process.env.REACT_APP_BASE_URL}/auth/tutor/google`
          : "https://liah-edu-server.liahstudy.online/auth/tutor/google";

      // Redirect to the appropriate Google sign-in URL
      window.location.href = googleAuthUrl;
    } catch (error) {
      console.error("Google sign-in failed:", error);
      setIsGoogleAuthLoading(false); // Only set loading to false if there's an error
    }
  };

  const googleSignUp = async () => {
    setIsGoogleAuthLoading(true);
    const googleAuthUrl =
      process.env.NODE_ENV !== "production"
        ? "http://localhost:8000/auth/tutor/google/signup"
        : process.env.REACT_APP_BASE_URL
        ? `${process.env.REACT_APP_BASE_URL}/auth/tutor/google/signup`
        : "https://liah-edu-server.liahstudy.online/auth/tutor/google/signup";

    // Redirect to the appropriate Google sign-up URL
    window.location.href = googleAuthUrl;
    setIsGoogleAuthLoading(false);
  };

  // Password strength handling
  useEffect(() => {
    if (formik.values.password) {
      const result = zxcvbn(formik.values.password);
      setPasswordStrength(result.score);
      setPasswordStrengthMessages(
        result.feedback.suggestions.length > 0
          ? result.feedback.suggestions
          : [
              "Use a strong password with a mix of letters, numbers, and symbols.",
            ]
      );
    }
  }, [formik.values.password]);

  const isFormComplete = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const { email, password, passwordConfirm, firstName, lastName, tel } =
      formik.values;

    if (intent === "signup") {
      return (
        email !== "" &&
        emailRegex.test(email) &&
        password !== "" &&
        passwordConfirm !== "" &&
        firstName !== "" &&
        lastName !== "" &&
        tel !== "" &&
        password === passwordConfirm &&
        passwordStrength >= 3
      );
    } else {
      return email !== "" && emailRegex.test(email) && password !== "";
    }
  };

  return {
    t,
    msg,
    google: {
      handleSignin: googleSignIn,
      handleSignup: googleSignIn,
      isGoogleAuthLoading,
    },
    phoneInput: {
      handleBlur,
      handleFocus,
      phoneInputActive,
    },
    inputValues: formik.values,
    passwordStrengthMessagesFixed,
    formik,
    passwordStrength,
    passwordStrengthMessages,
    togglePasswordVisibility,
    togglePasswordConfirmVisibility,
    showPassword,
    showPasswordConfirm,
    isLoading: formik.isSubmitting,
    isFormComplete,
  };
};

export const useGoogleAuth = () => {
  const [loading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const toast = useToast();

  useEffect(() => {
    // Parse the token and next route from the URL parameters
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get("token");
    const nextPath = urlParams.get("next");
    const error = urlParams.get("error");
    const message = urlParams.get("message");
    if (error && message) {
      toast({
        title: error,
        description: message,
        status: "error",
        duration: 5000,
      });
    }

    if (token) {
      setIsLoading(true);
      dispatch(userActions.getUserInfo({ token }))
        .unwrap()
        .then(() => {
          setIsLoading(false);
          localStorage.setItem(principalTokenKey, token);
          navigate(nextPath ?? "/dashboard");
        })
        .catch((e) => {
          setIsLoading(true);
          console.log(e);
        });
    }
  }, [location]);

  const handleSignin = async () => {
    const googleAuthUrl =
      process.env.NODE_ENV !== "production"
        ? "http://localhost:8000/auth/tutor/google"
        : process.env.REACT_APP_BASE_URL
        ? `${process.env.REACT_APP_BASE_URL}/auth/tutor/google`
        : "https://liah-edu-server.liahstudy.online/auth/tutor/google";
    setIsLoading(true);
    window.location.href = googleAuthUrl;
  };

  return {
    loading,
    handleSignin,
  };
};
export const useAddPhone = () => {
  const [phoneInputActive, setPhoneInputActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const [msg, setMsg] = useState<ToastMessage | null>(null);

  // Handle the phoneInputActiveState
  const handleFocus = () => {
    setPhoneInputActive(true);
  };
  const handleBlur = () => {
    setPhoneInputActive(false);
  };

  const validationSchema = Yup.object({
    tel: Yup.string().required("Phone number is required"),
  });

  function parseToken(token: string): { _id: string } | null {
    try {
      const decoded = jwtDecode<JwtPayload & { user: { _id: string } }>(token);
      return decoded.user;
    } catch (error) {
      console.error("Failed to parse token:", error);
      return null;
    }
  }
  // Formik setup
  const formik = useFormik({
    initialValues: {
      tel: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      setIsLoading(true); // Show loading indicator

      const token = localStorage.getItem(principalTokenKey) || "";
      const decoded = parseToken(token);

      // Check if the token is valid
      if (!decoded) {
        toast({
          title: "Error",
          description: "Failed to decode token",
          status: "error",
          duration: 3000,
          isClosable: false,
        });
        setIsLoading(false);
        return;
      }
      const updatedValues = {
        ...values,
        _id: decoded._id,
      };
      try {
        // Dispatch action with user ID and form values (ensure correct data structure)
        const response = await dispatch(
          userActions.updateUserInfo(updatedValues)
        )
          .unwrap()
          .then(() => {
            setMsg({
              severity: "success",
              summary: "Success",
              detail: "User info updated successfully",
              life: 3000,
            });
            navigate("/dashboard");
          });
      } catch (error: any) {
        // Handle error
        const message =
          error?.message || "Please check your internet connection";
        toast({
          title: "Error",
          description: message,
          status: "error",
          duration: 3000,
          isClosable: false,
        });

        // Optionally set any other error message or UI indicator
        setMsg({
          severity: "error",
          summary: "Error",
          detail: message,
          life: 3000,
        });
      } finally {
        setIsLoading(false); // Always hide loading indicator when done
      }
    },
  });

  return {
    t,
    msg,
    formik,
    isLoading,
    phoneInput: {
      handleBlur,
      handleFocus,
      phoneInputActive,
    },
  };
};
export const useOtpConfirmation = () => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [emailToUse, setEmailToUse] = useState("");
  const toast = useToast();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const value1 = useRef<HTMLInputElement | null>(null);
  const value2 = useRef<HTMLInputElement | null>(null);
  const value3 = useRef<HTMLInputElement | null>(null);
  const value4 = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    const userInfoString = localStorage.getItem(principalStorageKey);
    if (userInfoString) {
      const userInfo = JSON.parse(userInfoString);
      const email = userInfo.payload?.user?.email;
      setEmailToUse(email);
    }
  }, []);
  const handleConfirmOTP = async () => {
    if (
      !value1.current?.value ||
      !value2.current?.value ||
      !value3.current?.value ||
      !value4.current?.value
    ) {
      toast({
        title: "Invalid OTP",
        description: "Enter a valid OTP",
        status: "error",
        duration: 3000,
        isClosable: false,
      });
      return;
    }
    setIsLoading(true);

    if (emailToUse) {
      await dispatch(
        userActions.verifyOtpUser({
          otp: `${value1.current?.value}${value2.current?.value}${value3.current?.value}${value4.current?.value}`,
          email: emailToUse,
        })
      )
        .unwrap()
        .then((res) => {
          setError("");
          setIsLoading(false);
          toast({
            title: "Success",
            description: "You have successfully signed in",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          console.log(res);
          // const token = res.token;
          //     if (token) {
          //         dispatch(programActions.getMyTrainingPrograms(token));
          //     }
          navigate("/dashboard", {
            state: {
              otp: `${value1.current?.value}${value2.current?.value}${value3.current?.value}${value4.current?.value}`,
              from: "/verify-otp",
            },
          });
        })
        .catch((error) => {
          setError(error.message || "Error confirming OTP");
          setIsLoading(false);
          toast({
            title: "Error",
            description: error.message || "Error confirming OTP",
            status: "error",
            duration: 3000,
            isClosable: false,
          });
        });
    } else {
      console.log("No user info found in local storage");
      setIsLoading(false);
    }

    setIsLoading(false);

    if (error) {
      return toast({
        title: "Error",
        description: "Error confirming OTP",
        status: "error",
        duration: 3000,
        isClosable: false,
      });
    }
  };

  return {
    t,
    isLoading,
    handleConfirmOTP,
    value1,
    value2,
    value3,
    value4,
    emailToUse,
  };
};

const emailValidationSchema = Yup.object({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const passwordValidationSchema = Yup.object({
  password: Yup.string()
    .required("Password is required")
    .min(8, "Password must be at least 8 characters"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), ""], "Passwords must match")
    .required("Confirm password is required"),
});

export const useForgotPassword = () => {
  const { value1, value2, value3, value4, clearInput } = useHandleOtpInput();
  const { t } = useTranslation();
  const toast = useToast();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState<"email" | "otp" | "password">("email");
  const [serverError, setServerError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const [otp, setOtp] = useState<{
    value1: string;
    value2: string;
    value3: string;
    value4: string;
  }>({
    value1: "",
    value2: "",
    value3: "",
    value4: "",
  });

  const [passwordStrength, setPasswordStrength] = useState(0);
  const [passwordStrengthMessages, setPasswordStrengthMessages] = useState<
    string[]
  >([]);

  const passwordStrengthMessagesFixed = [
    "Very weak",
    "Weak",
    "Fair",
    "Strong",
    "Very strong",
  ];

  // Toggle password visibility
  const togglePasswordVisibility = () => setShowPassword(!showPassword);
  const togglePasswordConfirmVisibility = () =>
    setShowPasswordConfirm(!showPasswordConfirm);

  // Password strength checking logic
  const checkPasswordStrength = (value: string) => {
    const result = zxcvbn(value);
    setPasswordStrength(result.score);
    setPasswordStrengthMessages(
      result.feedback.suggestions.length > 0
        ? result.feedback.suggestions
        : ["Use a strong password with a mix of letters, numbers, and symbols."]
    );
  };

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: emailValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setServerError(null);
      try {
        await dispatch(userActions.recoverPassword(values))
          .unwrap()
          .then((res) => {
            setIsLoading(false);
            setStep("otp");
          });
        setIsSubmitted(true);
      } catch (error) {
        setServerError("Failed to send reset link. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    },
  });
  const handleOTPSubmit = async () => {
    const otpCode = [
      value1.current?.value,
      value2.current?.value,
      value3.current?.value,
      value4.current?.value,
    ].join("");

    if (otpCode.length !== 4) {
      setIsLoading(false);
      return;
    }
    // Get OTP value from the refs
    setOtp({
      value1: value1.current?.value ?? "",
      value2: value2.current?.value ?? "",
      value3: value3.current?.value ?? "",
      value4: value4.current?.value ?? "",
    });
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setStep("password");
    }, 2000);

    return;
    setIsLoading(true);
    setServerError(null);

    try {
      // Dispatch the action to verify OTP
      const response = await dispatch(
        userActions.verifyOtpUser({
          otp: "",
          email: formik.values.email,
        })
      ).unwrap();

      setServerError(null);
      toast({
        title: "Success",
        description: "OTP Correct",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      console.log(response);

      setStep("password"); // Move to the password reset step
    } catch (error: any) {
      setServerError(error.message || "Error confirming OTP");
      toast({
        title: "Error",
        description: error.message || "Error confirming OTP",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Formik for password reset
  const passwordFormik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: "",
    },
    validationSchema: passwordValidationSchema,
    onSubmit: async (values) => {
      setIsLoading(true);
      setServerError(null);
      const otpCode = `${otp.value1}${otp.value2}${otp.value3}${otp.value4}`;
      if (otpCode.length !== 4) {
        passwordFormik.values.confirmPassword = "";
        passwordFormik.values.password = "";
        setStep("otp");
        setIsLoading(false);
        setServerError("Please enter a valid 4-character OTP.");
        return;
      }
      await dispatch(
        userActions.resetPasswordUser({
          otp: otpCode,
          email: formik.values.email,
          password: passwordFormik.values.password,
          confirmPassword: passwordFormik.values.confirmPassword,
        })
      )
        .unwrap()
        .then(() => {
          setServerError(null);
          toast({
            title: "Success",
            description: "You have reset your password",
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          setIsSubmitted(true); // Successfully reset password
          setIsLoading(false);
          navigate("/signin");
        })
        .catch((err) => {
          if (err.message === "Invalid Otp") {
            setServerError("Invalid Otp");
            setStep("otp");
            clearInput();
          } else if (err.message === "Passwords do not match") {
            setServerError("Passwords do not match");
          } else if (err.message === "Otp has expired") {
            setOtp({
              value1: "",
              value2: "",
              value3: "",
              value4: "",
            });
            setStep("email");
            setServerError("Otp has expired");
          }
        });

      try {
        const response = await dispatch(
          userActions.resetPasswordUser({
            email: formik.values.email,
            otp: otpCode,
            password: passwordFormik.values.password,
            confirmPassword: passwordFormik.values.confirmPassword,
          })
        )
          .unwrap()
          .then((response) => {
            setServerError(null);
            toast({
              title: "Success",
              description: "You have reset your password",
              status: "success",
              duration: 3000,
              isClosable: true,
            });
            setIsSubmitted(true); // Successfully reset password
          })
          .catch((err) => {
            if (err.message === "Invalid Otp") {
              setServerError("Invalid Otp");
              setStep("otp");
              clearInput();
            }
          });
      } catch (error) {
        setServerError("Failed to reset password. Please try again.");
        toast({
          title: "Error",
          description: "Failed to reset password. Please try again.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  // Handle password input change and calculate strength
  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    passwordFormik.setFieldValue("password", value);
    const result = zxcvbn(value);
    setPasswordStrength(result.score);
    setPasswordStrengthMessages(
      result.feedback.suggestions.length > 0
        ? result.feedback.suggestions
        : ["Use a strong password with a mix of letters, numbers, and symbols."]
    );
  };
  return {
    t,
    step, // The current step in the flow
    value1,
    value2,
    value3,
    value4, // Refs for the OTP input fields
    formik, // Formik instance for the email input
    passwordFormik, // Formik instance for the password input
    isLoading, // Loading state for API interactions
    serverError, // Server error handling
    isSubmitted, // Flag indicating if the password reset is complete
    togglePasswordVisibility, // Toggle password visibility
    togglePasswordConfirmVisibility, // Toggle confirm password visibility
    showPassword, // Password visibility state
    showPasswordConfirm, // Confirm password visibility state
    passwordStrength, // Password strength score
    passwordStrengthMessages, // Password strength feedback messages
    passwordStrengthMessagesFixed, // Fixed messages for strength levels
    onPasswordChange, // Function to handle password input changes
    handleOTPSubmit, // Handler for OTP form submission
    checkPasswordStrength,
    handlePasswordSubmit: passwordFormik.handleSubmit, // Handler for password reset form submission
  };
};
