import React, { useEffect } from "react";
import { HStack, Box } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../app/hooks";
import Carousel from "../components/Carousel/Carousel";
import { selectAuthPayload } from "../utils/authSelector";

type AuthLayoutProps = {
  children: React.ReactNode;
};

const AuthLayout: React.FC<AuthLayoutProps> = ({ children }) => {
  const navigate = useNavigate();
  const { payload } = useAppSelector(selectAuthPayload);

  useEffect(() => {
    if (payload?.user) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payload?.user]);

  return (
    <HStack h="100vh" m={0}>
      {children}
      <Box
        display={{ base: "none", md: "inline-block" }}
        h="100vh"
        w="70%"
        overflow="hidden"
      >
        <Carousel />
      </Box>
    </HStack>
  );
};

export default AuthLayout;
