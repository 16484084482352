import { useToast } from "@chakra-ui/react";
import { ChangeEvent, useEffect, useState } from "react";
import { uploadProfilePhoto } from "../api/user.api";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { Tutor } from "../../types";
import { userActions } from "../store/features/slices/authSlice";

export const useGetAuthUser = () => {
  const [user, setUser] = useState<Tutor>();
  const { payload, loading } = useAppSelector((state) => state.auth);
  useEffect(() => {
    setUser(payload?.user);
  }, [payload?.user]);
  return { user, loading };
};

export const useUploadPhoto = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useGetAuthUser();
  const [file, setFile] = useState<File | null>(null);
  const [fileSize, setFileSize] = useState(0);
  const toast = useToast();
  const dispatch = useAppDispatch();

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const selectedFile = e.target.files[0];
      setFile(selectedFile);
      setFileSize(selectedFile.size);
    }
  };

  const onSubmit = async (fn?: () => void) => {
    if (!file || !user?.email) return;
    if (fileSize > 3145728) {
      toast({
        title: "File too Large",
        status: "warning",
        duration: 3000,
      });
      return;
    }
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("email", user?.email ?? "");
      formData.append("file", file);
      setIsLoading(true);
      await uploadProfilePhoto(formData).then(() => {
        dispatch(userActions.getUserInfo())
          .unwrap()
          .then(() => {
            toast({
              title: "Image uploaded successfully",
              status: "success",
              duration: 3000,
            });
            fn?.();
            setIsLoading(false);
          });
      });
    } catch (error) {
      toast({
        title: "Error uploading image",
        status: "error",
        duration: 3000,
      });
      //   setError("An error occurred while uploading the image");
      setIsLoading(false);
    }
  };
  return {
    onSubmit,
    isLoading,
    image: file,
    handleFileChange,
    photoUrl: user?.photoURL,
  };
};
