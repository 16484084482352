import { UpdateUserInfo } from "../../types";
import { principalTokenKey } from "../constants";
import {
  PrincipalResponse,
  SigninResponse,
  TSignUpUser,
  TSocial,
} from "../types";
import { backend, endpoints } from "./config.api";

export const getMe = (token?: string) => {
  if (token) {
    return backend.get<PrincipalResponse>(endpoints.tutors.me, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }
  return backend.get<PrincipalResponse>(endpoints.tutors.me);
};
export const getTutorByEmail = (email: string) =>
  backend.post<PrincipalResponse>(endpoints.tutors.me, {
    email,
  });
export const recoverTutorPassword = (email: string) =>
  backend.post<SigninResponse>(endpoints.auth.recoverPassword, {
    email,
  });
export const signin = (email: string, password: string) =>
  backend.post<PrincipalResponse & { token: string; message: string }>(
    endpoints.auth.signin,
    {
      email,
      password,
    }
  );

export const signup = (data: TSignUpUser) =>
  backend.post<PrincipalResponse>(endpoints.auth.signup, {
    ...data,
  });

export const verifyOtp = (otp: string, email: string) =>
  backend.post<{}>(endpoints.auth.verifyOtp, {
    otp,
    email,
  });
export const forgetPassword = (email: string) =>
  backend.post<PrincipalResponse>(endpoints.auth.forgetPassword, {
    email,
  });
export const resetPassword = (data: {
  otp: string;
  email: string;
  password: string;
  confirmPassword: string;
}) => backend.post<PrincipalResponse>(endpoints.auth.resetPassword, data);

export const uploadPhoto = (data: { email: string; file: File }) => {
  const token = localStorage.getItem(principalTokenKey) || "";
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("file", data.file);
  return backend.post<PrincipalResponse>(endpoints.auth.uploadPhoto, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });
};
export const uploadProfilePhoto = (data: FormData) =>
  backend.post<PrincipalResponse>(endpoints.auth.uploadPhoto, data);

export const updateUserInformation = (data: UpdateUserInfo) =>
  backend.patch<PrincipalResponse>(endpoints.auth.updateUserInfo, data);

export const addBio = (data: { email: string; text: string }) =>
  backend.post<PrincipalResponse>(endpoints.auth.uploadBio, data);

// export const uploadSocial = (social: TSocial) =>
//   backend.post<PrincipalResponse>(endpoints.auth.uploadSocial);

export const uploadSocial = (data: { social: TSocial; email: string }) =>
  backend.post<PrincipalResponse>(endpoints.auth.uploadSocial, data);
