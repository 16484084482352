import { useAppSelector } from "../../app/hooks";
import { FaFacebook, FaInstagram, FaLinkedin, } from "react-icons/fa";
import XLogo from "../../assets/images/Xlogo.png"
import { IconType } from "react-icons";
import { motion } from "framer-motion";
import { Box } from "@chakra-ui/react";
import { useState } from "react";
import HeroGradient from "../../assets/images/Hero-Gradient.png"

type IconItem = {
    icon: IconType | string; // Allows either an IconType or a string for the image path
    label: string;
    link: string;
  };
export const useHero = () => {
    const { payload } = useAppSelector((state) => state.auth);
    const user = payload?.user || null
    const [heroGradientLoaded, setHeroGradientLoaded] = useState(false)
    const [heroGradientError, setHeroGradientError] = useState(false)
    
    const icons: IconItem[] = [
        { icon: FaFacebook, label: 'Facebook', link: "https://www.facebook.com/tekstedia/" },
        { icon: FaLinkedin, label: 'LinkedIn', link: "https://www.linkedin.com/company/tekstedia" },
        { icon: FaInstagram, label: 'Instagram', link: "https://instagram.com" },
        { icon: XLogo, label: 'Twitter', link: "https://x.com" },
      ];
    return {
        user,icons,heroGradientLoaded, setHeroGradientLoaded, heroGradientError, setHeroGradientError, HeroGradient
    }
}