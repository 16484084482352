import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { useAppSelector } from "../app/hooks";
import { ErrorType } from "../../types";
import eventBus from "../utils/eventbus";
import AppContent from "./AppContent";
import { useCheckTokenValidity, useListenToEvent } from "../hooks/common.hooks";

const AppRouter: React.FC = () => {
  const isModal = useAppSelector((state) => state.utility.isModalOpen);
  const {
    error,
    isOpen,
    setError,
    setIsOpen,
    handleButtonAction: handleClose,
  } = useCheckTokenValidity();
  const { handler } = useListenToEvent();

  useEffect(() => handler(() => handleClose), []);

  useEffect(() => {
    const handleErrorModal = (errorData: ErrorType) => {
      setError(errorData);
      setIsOpen(true);
    };
    eventBus.on("showErrorModal", handleErrorModal);
    return () => {
      eventBus.off("showErrorModal", handleErrorModal);
    };
  }, []);

  return (
    <HelmetProvider>
      <Router>
        <AppContent
          isModal={isModal}
          isOpen={isOpen}
          handleClose={handleClose}
          error={error}
        />
      </Router>
    </HelmetProvider>
  );
};

export default AppRouter;
