import "./authStyles.css"
import {
    Flex,
    FormControl,
    Button,
    Text,
    VStack,
    useColorModeValue,
    Input,
} from "@chakra-ui/react";
import { Logo } from "../../components/common/Logo";
import { Helmet } from "react-helmet-async";
import PhoneInput from "react-phone-number-input";
import { useAddPhone } from "../../hooks/auth.hooks";
import { useEffect, useState } from "react";
import { useQueryParams } from "../../hooks";
import { principalTokenKey } from "../../constants";


const AddPhone = () => {
    const {
        msg,
        t,
        phoneInput,
        formik,
        isLoading,
    } = useAddPhone();
    const [error, setError] = useState<string | null>(null);
  const q = useQueryParams()
  useEffect(() => {

    // Check if there is an error in the query parameters
    const errorParam = q['error'];
    const token = q['token'];

    if (errorParam) {
      setError(decodeURIComponent(errorParam));
    } else if (token) {
      // Successfully authenticated, handle token (e.g., save in localStorage)
      localStorage.setItem(principalTokenKey, token);
      // navigate('/dashboard');  // Redirect to the dashboard
    }
  }, [q]);
    return (
        <VStack
            w={{ base: "100%", md: "50%" }}
            align="flex-start"
            justify="space-between"
            h="full"
            p={{ base: 0, sm: 1, md: 4 }}
            overflowY="hidden"
        >
            <Helmet>
                <title>{t("addphone.title")}</title>
                <meta
                    name="description"
                    content={t("addphone.metaDescription")}
                />
            </Helmet>
            <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
                <VStack
                    bg={useColorModeValue("white", "gray.800")}
                    px={4}
                    py={8}
                    rounded="md"
                    w="100%"
                    align="flex-start"
                >
                    <Logo />
                    <Text
                        as="h3"
                        fontWeight="600"
                        fontSize={{ base: "20px", md: "35px" }}
                        mb={4}
                        textAlign="left"
                        w="full"
                    >
                        {t("addphone.heading")}
                    </Text>
                    <FormControl>
                        <Flex
                            rounded={"4px"}
                            fontSize={{ base: "xs", md: "sm" }}
                            w="full"
                            h="full"
                            onFocus={phoneInput.handleFocus}
                            onBlur={phoneInput.handleBlur}
                            tabIndex={0}
                            border={phoneInput.phoneInputActive ? "2px solid #3182ce" : "2px solid gainsboro"}
                        >
                            <Input
                                as={PhoneInput}
                                border="none"
                                p={"0.75em"}
                                required
                                value={formik.values.tel}
                                onChange={(value) => formik.setFieldValue("tel", value)}
                                onFocus={phoneInput.handleFocus}
                                onBlur={phoneInput.handleBlur}
                                style={{
                                    width: "100%",
                                    height: "100%",
                                    fontSize: "12px",
                                    outline: "none",
                                }}
                                isInvalid={formik.touched.tel && !!formik.errors.tel}
                                placeholder={t("signup.phonePlaceholder")}
                            />
                        </Flex>
                    </FormControl>
                    <Button
                        type="submit"
                        variant={"primary"}
                        w="100%"
                        isLoading={isLoading}
                        loadingText="Submitting"
                        isDisabled={!formik.isValid || !formik.dirty || isLoading}
                    >
                        {t("addphone.submitButtonText")}
                    </Button>

                </VStack>
            </form>
        </VStack>
    )
}

export default AddPhone;