import React, { useEffect, useMemo } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { principalTokenKey } from "../constants";
import { selectAuthPayload } from "../utils/authSelector";
import Loading from "../components/loading/Loading";
import { useQueryParams } from "../hooks";
import { userActions } from "../store/features/slices/authSlice";
import { useGetAuthUser } from "../hooks/user.hook";
import { PATH_NAMES } from "./path-names";

type AuthGuardProps = {
  children: React.ReactNode;
  failTo: string;
  guardWhat?: string;
  target?: string;
};

export const AuthGuard: React.FC<AuthGuardProps> = ({
  children,
  failTo,
  guardWhat,
  target,
}) => {
  const { user } = useGetAuthUser();
  const navigate = useNavigate();

  if (guardWhat === "onboarding") {
    if (user?.profileComplete) {
      navigate("/dashboard");
    }
  }
  // useEffect(() => {
  //   dispatch(userActions.getUserInfo());
  // }, []);

  // useEffect(() => {
  //   if (!payload?.user) {
  //     navigate("/signin");
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [payload?.user]);
  // Use memoization to avoid recalculating token and auth status on every render
  // Memoize authentication status to avoid recalculating unnecessarily
  // const isAuthenticated = useMemo(() => {
  //   const tokenStore = localStorage.getItem(principalTokenKey);
  //   const tokenURL = q["token"];
  //   const token = tokenStore ?? tokenURL;
  //   // Use token-based authentication fallback while payload is loading
  //   if (!loading && token) {
  //     localStorage.setItem(principalTokenKey, token);
  //     if (!payload?.user) {
  //       // console.log("Waiting for user data but token is available");
  //       return true; // Token exists, wait for Redux to populate the user data
  //     }
  //     // When payload is fully available, check email verification
  //     return payload?.user && payload?.user.emailVerified === true && !!token;
  //   }
  //   return false; // Not authenticated
  // }, [payload, loading, q]);

  // // Render nothing if still loading (you can replace this with a spinner or skeleton)
  // if (loading) return <Loading />;

  // // If authenticated and the user is trying to access the signin route, redirect to the dashboard
  // if (isAuthenticated && location.pathname === "/signin") {
  //   console.log("User is already authenticated, redirecting to dashboard");
  //   return <Navigate to="/dashboard" />;
  // }

  // Redirect to `failTo` route if the user is not authenticated
  // if (!loading && user) {
  //   console.log("Redirecting due to failed authentication");
  //   return <Navigate to={failTo} />;
  // }

  useEffect(() => {
    if (user) {
      if (target === PATH_NAMES.ADD_PHONE) {
        navigate("/add-phone");
      }
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // // Log the guarded route for debugging purposes, if needed
  // if (guardWhat) {
  //   console.log(`Guarding: ${guardWhat}`);
  // }

  // If authenticated and not on the /signin route, render the children
  return <>{children}</>;
};
