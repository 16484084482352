import { useLocation } from "react-router-dom";
import { excludedPaths } from "./excludedPaths";
import NavWithSubnavigation from "../components/Navbar/Navbar";

interface HeaderRoutesProps {
  colored?: boolean
}
export const HeaderRoutes = ({colored}: HeaderRoutesProps) => {
    const location = useLocation();
  
  
    const shouldRenderHeader = !excludedPaths.some((path) =>
      location.pathname.startsWith(path)
    );
  
    // Render LandingHeader if shouldRenderHeader is true, otherwise null
    return shouldRenderHeader ? <NavWithSubnavigation colored={colored ?? true} /> : null;
  };