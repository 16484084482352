import { AxiosError } from "axios";
import { ApiError, ApiException } from "../types";
import { TrainingProgram } from "../../types";
import { jwtDecode, JwtPayload } from "jwt-decode";

export interface AsyncState<T, E = unknown> {
  payload?: T;
  loading: boolean;
  errors?: E[];
}

export interface AsyncStateTP<T, E = unknown> {
  payload?: T;
  uploadProgress: number;
  loading: boolean;
  errors?: E[];
}

export const retrieveErrorResponse = <
  T extends ApiException | ApiError[] = ApiException
>(
  e: unknown
): T | undefined => {
  if (isAxiosError<T>(e)) {
    return e?.response?.data as T;
  }
};

export const isAxiosError = <T>(e: unknown): e is AxiosError<T> => {
  return (e as AxiosError<T>).isAxiosError;
};

export const isValidFileSize = (file: File): boolean => {
  const maxSize = 10 * 1024 * 1024; // 10 MB in bytes
  return file.size <= maxSize;
};

export const isValidFileType = (file: File): boolean => {
  const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
  return allowedTypes.includes(file.type);
};

export const currentPathName = () => window?.location?.pathname;

export const currentSearchParams = () =>
  new URLSearchParams(window?.location?.search);

export const slotText = (text: string) =>
  text.split(" ").join("-").toLowerCase();

export function formatDate(date: string | Date): string {
  const dateObject = typeof date === "string" ? new Date(date) : date;
  const month = dateObject.toLocaleString("default", { month: "short" });
  const day = dateObject.getDate();
  const year = dateObject.getFullYear();
  return `${month} ${day}, ${year}`;
}

// Type guard for File
export function isFile(value: any): value is File {
  return value instanceof File;
}

// Type guard for Blob
export function isBlob(value: any): value is Blob {
  return value instanceof Blob;
}

/**
 * Checks if the input string contains any special characters.
 * @param input - The string to check.
 * @returns True if the string contains special characters, false otherwise.
 */
export const hasSpecialCharacters = (input: string): boolean => {
  const specialChars = /[!@#$%^&*(),.?":{}|<>]/g;
  const urlUnsafeChars = /[^a-zA-Z0-9-._~ ]/g;

  return specialChars.test(input) || urlUnsafeChars.test(input);
};

/**
 * Checks if all properties of an object are filled.
 * @param form - The object to check.
 * @returns True if all properties are filled, false otherwise.
 */
export const isFormComplete = (form: Record<string, any>): boolean => {
  for (const key in form) {
    const value = form[key];
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      (typeof value === "number" && value < 0)
    ) {
      return false;
    }
  }
  return true;
};

/**
 * Checks if all string provided is an email
 * @param email - The string to check.
 * @returns `True` if String is Email.
 */
export const isEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const scrollToBottom = (duration: number = 2000): void => {
  const start = window.scrollY;
  const end = document.body.scrollHeight - window.innerHeight;
  const change = end - start;
  let startTime: number | null = null;

  const animateScroll = (currentTime: number) => {
    if (startTime === null) startTime = currentTime;
    const timeElapsed = currentTime - startTime;
    const run = easeInOutQuad(timeElapsed, start, change, duration);
    window.scrollTo(0, run);
    if (timeElapsed < duration) requestAnimationFrame(animateScroll);
  };

  const easeInOutQuad = (
    t: number,
    b: number,
    c: number,
    d: number
  ): number => {
    t /= d / 2;
    if (t < 1) return (c / 2) * t * t + b;
    t--;
    return (-c / 2) * (t * (t - 2) - 1) + b;
  };

  requestAnimationFrame(animateScroll);
};

// Helper function to extract the video duration from a file or URL
export const getVideoDuration = (file: File | string): Promise<number> => {
  return new Promise((resolve, reject) => {
    const videoElement = document.createElement("video");

    videoElement.preload = "metadata";

    // If the file is a URL string, use it directly
    if (typeof file === "string") {
      videoElement.src = file;
    } else {
      // Create a URL for the File object
      const videoURL = URL.createObjectURL(file);
      videoElement.src = videoURL;
    }

    videoElement.onloadedmetadata = () => {
      URL.revokeObjectURL(videoElement.src); // Release the object URL to free up memory
      resolve(videoElement.duration); // Return the video duration in seconds
    };

    videoElement.onerror = () => {
      reject("Error loading video");
    };
  });
};

export const urlToFile = async (
  url: string,
  filename: string,
  mimeType: string
): Promise<File> => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new File([blob], filename, { type: mimeType });
};

export const getNumberOfLessons = (
  trainingProgram: TrainingProgram | undefined
): number => {
  if (trainingProgram) {
    return trainingProgram.courses.reduce((courseCount, course) => {
      return (
        courseCount +
        course.modules.reduce((moduleCount, module) => {
          return moduleCount + module.lessons.length;
        }, 0)
      );
    }, 0);
  } else {
    return 0;
  }
};

export const getNumberOfReviews = (
  trainingProgram: TrainingProgram | undefined
): number => {
  if (trainingProgram) {
    return trainingProgram.data?.reviews.length!;
  } else {
    return 0;
  }
};

export const getNumberOfStudents = (
  trainingProgram: TrainingProgram | undefined
): number => {
  if (trainingProgram) {
    return trainingProgram.data?.numOfStudents!;
  } else {
    return 0;
  }
};

export const getProgramLanguage = (
  trainingProgram: TrainingProgram | undefined
): string => {
  if (trainingProgram) {
    return trainingProgram.language;
  } else {
    return "English";
  }
};

export const getProgramLevel = (
  trainingProgram: TrainingProgram | undefined
): string => {
  if (trainingProgram) {
    return trainingProgram.level;
  } else {
    return "Amateur";
  }
};

export const getProgramFilesCount = (
  trainingProgram: TrainingProgram | undefined
): number => {
  // Initialize a counter for attachments
  let totalAttachments = 0;
  if (trainingProgram) {
    // Loop through each course
    trainingProgram.courses.forEach((course) => {
      // Loop through each module within the course
      course.modules.forEach((module) => {
        // Loop through each lesson within the module
        module.lessons.forEach((lesson) => {
          // If the lesson has attachments, count them
          if (lesson.attachments && lesson.attachments.length > 0) {
            totalAttachments += lesson.attachments.length;
          }
        });
      });
    });
  }

  return totalAttachments;
};
export async function getProgramDuration(
  trainingProgram: TrainingProgram | undefined
): Promise<string> {
  // Define average lesson duration without video (in minutes)
  const avgLessonDurationMinutes = 30; // Time spent on a lesson without a video

  // Initialize a counter for total minutes
  let totalMinutes = 0;

  // Function to convert seconds to minutes
  const secondsToMinutes = (seconds: number) => seconds / 60;

  if (trainingProgram) {
    // Handle intro video (if available)
    if (trainingProgram.introVideo) {
      try {
        const introVideoFile = await urlToFile(
          trainingProgram.introVideo,
          "intro.mp4",
          "video/mp4"
        );
        const introVideoDuration = await getVideoDuration(introVideoFile);
        totalMinutes += secondsToMinutes(introVideoDuration);
      } catch (error) {
        console.error("Error fetching intro video duration:", error);
      }
    }

    // Loop through each course
    for (const course of trainingProgram.courses) {
      // Loop through each module in the course
      for (const module of course.modules) {
        // Loop through each lesson within the module
        for (const lesson of module.lessons) {
          // Add the base lesson duration
          totalMinutes += avgLessonDurationMinutes;

          // Handle video duration if a video exists
          if (lesson.video) {
            try {
              let videoFile: File;

              if (typeof lesson.video === "string") {
                // Fetch video file from the URL
                videoFile = await urlToFile(
                  lesson.video,
                  "lesson.mp4",
                  "video/mp4"
                );
              } else if (lesson.video instanceof File) {
                // Use the existing File object
                videoFile = lesson.video;
              } else {
                continue; // Skip if video format is not supported
              }

              // Get the actual video duration
              const videoDuration = await getVideoDuration(videoFile);
              totalMinutes += secondsToMinutes(videoDuration);
            } catch (error) {
              console.error(
                `Error fetching video duration for lesson "${lesson.title}":`,
                error
              );
            }
          }
        }
      }
    }
  }

  // Convert total minutes into hours and days for a human-readable format
  const totalHours = Math.floor(totalMinutes / 60);
  const days = Math.floor(totalHours / 24);
  const hours = totalHours % 24;
  const minutes = totalMinutes % 60;

  // Create a human-readable string for the program duration
  let durationStr = "";
  if (days > 0) durationStr += `${days} day${days > 1 ? "s" : ""} `;
  if (hours > 0) durationStr += `${hours} hour${hours > 1 ? "s" : ""} `;
  if (minutes > 0) durationStr += `${minutes} minute${minutes > 1 ? "s" : ""}`;

  // Return the duration string or a default value if no lessons were found
  return durationStr.trim() || "0 minutes";
}

export function stringToBoolean(str: "true" | "false") {
  if (str) return str.toLowerCase() === "true";
  else {
    return false;
  }
}

export const hashValue = async (value: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(value); // Convert string to Uint8Array
  const hashBuffer = await crypto.subtle.digest("SHA-256", data); // Hash the data
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // Convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join(""); // Convert byte array to hex string
  return hashHex;
};

// import { JwtPayload, jwtDecode } from "jwt-decode";

/**
 * @description Returns true if token has expired
 * @param token  string
 * @returns boolean
 */
export const isTokenExpired = (token: string): boolean => {
  const decoded = jwtDecode<JwtPayload & { user: { _id: string } }>(token);
  const currentTime = Date.now() / 1000; // Convert milliseconds to seconds

  if (!decoded.exp) {
    return false; // If no expiration is set, assume the token is not expired
  }

  // Return true if the token is expired
  return decoded.exp < currentTime;
};
