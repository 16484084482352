import "./authStyles.css"
import {
  Flex,
  FormControl,
  Button,
  Text,
  VStack,
  useColorModeValue,
  InputGroup,
  InputRightElement,
  IconButton,
  Progress,
  Input,
  Box,
  Divider,
} from "@chakra-ui/react";
import { FcGoogle } from "react-icons/fc";
import { Link } from "react-router-dom";
import { Logo } from "../../components/common/Logo";
import { Helmet } from "react-helmet-async";
import PhoneInput from "react-phone-number-input";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useSignupLogin } from "../../hooks/auth.hooks";


export default function SignUp() {
  const {
    msg,
    t,
    google,
    phoneInput,
    formik,
    passwordStrengthMessagesFixed,
    passwordStrength,
    passwordStrengthMessages,
    togglePasswordConfirmVisibility,
    togglePasswordVisibility,
    showPassword,
    showPasswordConfirm,
    isLoading,
  } = useSignupLogin("signup");
  return (
    <VStack
      w={{ base: "100%", md: "50%" }}
      alignItems="flex-start"
      justifyContent="space-between"
      h="full"
      overflowY="auto"
      css={{
        "&::-webkit-scrollbar": {
          height: "1px",
          width: "2.3px",
        },
        "&::-webkit-scrollbar-thumb": {
          backgroundColor: "#dadbdc",
          borderRadius: "9px",
        },
      }}
      p={{ base: "1px", sm: "2px", md: 4, lg: 5, xl: 6 }}
    >
      <Helmet>
        <title>{t("signup.title")}</title>
        <meta name="description" content={t("signup.metaDescription")} />
      </Helmet>

      <VStack
        bg={useColorModeValue("white", "black")}
        px="10px"
        rounded={"md"}
        py={10}
        alignItems="flex-start"
        w="100%"
        pt={8}
      >
        <Logo />
        <Text textAlign="left" fontSize={"sm"} color={useColorModeValue("gray.500", "white")}>
          {t("signup.heading")}
        </Text>
        <Text color={useColorModeValue("primary", "blue.300")}>
          {t("signup.subheading")}
        </Text>
        <FormControl w="100%">
          <Button
            leftIcon={<FcGoogle />}
            variant="googleBtn"
            w="100%"
            onClick={google.handleSignup}
            isLoading={google.isGoogleAuthLoading}
            loadingText="Googling..."
          >
            {t("signup.googleSignupButton")}
          </Button>
        </FormControl>

        <Flex align="center" width="full" justify="space-between" my={4}>
          <Box flex="1">
            <Divider />
          </Box>
          <Text color="gray.500" fontWeight="400" fontSize="sm" px={2}>
            {t("signup.orText")}
          </Text>
          <Box flex="1">
            <Divider />
          </Box>
        </Flex>

        <form
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "6px",
          }}
          onSubmit={formik.handleSubmit}
        >
          <FormControl>
            <Input
              required
              rounded={"5px"}
              placeholder={t("signup.firstNamePlaceholder")}
              _placeholder={{ color: "gray.500", fontSize: "12" }}
              type="text"
              name="firstName"
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              _focus={{ outline: "none" }}
              fontSize={{ base: "xs", md: "sm" }}
              w="full"
              border={"1px solid gainsboro"}
              bg={useColorModeValue("white", "black")}
              p={"0.5em"}
              borderWidth={"2px"}
              isDisabled={google.isGoogleAuthLoading}
              isInvalid={formik.touched.firstName && !!formik.errors.firstName}
            />
          </FormControl>

          <FormControl>
            <Input
              required
              rounded={"5px"}
              placeholder={t("signup.lastNamePlaceholder")}
              _placeholder={{ color: "gray.500", fontSize: "12" }}
              type="text"
              name="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              _focus={{ outline: "none" }}
              fontSize={{ base: "xs", md: "sm" }}
              w="full"
              border={"1px solid gainsboro"}
              isDisabled={google.isGoogleAuthLoading}
              bg={useColorModeValue("white", "black")}
              p={"0.5em"}
              borderWidth={"2px"}
              isInvalid={formik.touched.lastName && !!formik.errors.lastName}
            />
          </FormControl>

          <FormControl>
            <Flex
              rounded={"4px"}
              fontSize={{ base: "xs", md: "sm" }}
              w="full"
              h="full"
              onFocus={phoneInput.handleFocus}
              onBlur={phoneInput.handleBlur}
              tabIndex={0}
              border={phoneInput.phoneInputActive ? "2px solid #3182ce" : "2px solid gainsboro"}
            >
              <Input
                as={PhoneInput}
                border="none"
                p={"0.75em"}
                required
                value={formik.values.tel}
                onChange={(value) => formik.setFieldValue("tel", value)}
                onFocus={phoneInput.handleFocus}
                onBlur={phoneInput.handleBlur}
                style={{
                  width: "100%",
                  height: "100%",
                  fontSize: "12px",
                  outline: "none",
                }}
                isDisabled={google.isGoogleAuthLoading}
                isInvalid={formik.touched.tel && !!formik.errors.tel}
                placeholder={t("signup.phonePlaceholder")}
              />
            </Flex>
          </FormControl>

          <FormControl>
            <Input
              required
              rounded={"5px"}
              placeholder={t("signup.emailPlaceholder")}
              _placeholder={{ color: "gray.500", fontSize: "12" }}
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              _focus={{ outline: "none" }}
              fontSize={{ base: "xs", md: "sm" }}
              w="full"
              isDisabled={google.isGoogleAuthLoading}
              border={"1px solid gainsboro"}
              bg={useColorModeValue("white", "black")}
              p={"0.5em"}
              borderWidth={"2px"}
              isInvalid={formik.touched.email && !!formik.errors.email}
            />
          </FormControl>

          <FormControl>
            <InputGroup>
              <Input
                required
                rounded={"5px"}
                placeholder={t("signup.passwordPlaceholder")}
                _placeholder={{ color: "gray.500", fontSize: "12" }}
                type={showPassword ? "text" : "password"}
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                _focus={{ outline: "none" }}
                fontSize={{ base: "xs", md: "sm" }}
                w="full"
                border={"1px solid gainsboro"}
                bg={useColorModeValue("white", "black")}
                p={"0.5em"}
                isDisabled={google.isGoogleAuthLoading}
                borderWidth={"2px"}
                isInvalid={formik.touched.password && !!formik.errors.password}
              />
              <InputRightElement width="3rem">
                <IconButton
                  h="1.5rem"
                  variant="unstyled"
                  size="sm"
                  onClick={togglePasswordVisibility}
                  icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                  aria-label={"Toggle Password visibility"}
                />
              </InputRightElement>
            </InputGroup>
            {passwordStrength < 3 && (
              <Text fontSize="xs" color="red.500" mt={1}>
                {passwordStrengthMessages}
              </Text>
            )}
            {passwordStrength > 0 && (
              <>
                <Progress
                  value={passwordStrength * 25}
                  size="xs"
                  colorScheme={
                    passwordStrength < 2 ? "red" : passwordStrength < 3 ? "yellow" : "green"
                  }
                  mt="1"
                />
                <Text fontSize="xs" color={passwordStrength < 3 ? "red.500" : "green.500"}>
                  Password strength: {passwordStrengthMessagesFixed[passwordStrength]}
                </Text>
              </>
            )}
          </FormControl>

          <FormControl>
            <InputGroup>
              <Input
                required
                rounded={"5px"}
                placeholder={t("signup.passwordConfirmPlaceholder")}
                _placeholder={{ color: "gray.500", fontSize: "12" }}
                type={showPasswordConfirm ? "text" : "password"}
                name="passwordConfirm"
                value={formik.values.passwordConfirm}
                onChange={formik.handleChange}
                isDisabled={google.isGoogleAuthLoading}
                onBlur={formik.handleBlur}
                _focus={{ outline: "none" }}
                fontSize={{ base: "xs", md: "sm" }}
                w="full"
                border={"1px solid gainsboro"}
                bg={useColorModeValue("white", "black")}
                p={"0.5em"}
                borderWidth={"2px"}
                isInvalid={formik.touched.passwordConfirm && !!formik.errors.passwordConfirm}
              />
              <InputRightElement width="3rem">
                <IconButton
                  h="1.5rem"
                  size="sm"
                  variant="unstyled"
                  onClick={togglePasswordConfirmVisibility}
                  icon={showPasswordConfirm ? <ViewOffIcon /> : <ViewIcon />}
                  aria-label={"Toggle Password confirmation visibility"}
                />
              </InputRightElement>
            </InputGroup>
            {formik.errors.passwordConfirm && formik.touched.passwordConfirm && (
              <Text fontSize="xs" color="red.500">
                {formik.errors.passwordConfirm}
              </Text>
            )}
          </FormControl>

          <Text fontSize={12}>
            {t("signup.termsOfServiceConsent")}{" "}
            <Text as="span" color={useColorModeValue("primary", "blue.300")} cursor={"pointer"}>
              {t("signup.termsOfService")}
            </Text>{" "}
            {t("signup.and")}{" "}
            <Text as="span" color={useColorModeValue("primary", "blue.300")} cursor={"pointer"}>
              {t("signup.privacyPolicy")}
            </Text>
            .
          </Text>

          <Button
            type="submit"
            variant={"primary"}
            w="100%"
            isLoading={isLoading}
            loadingText="Submitting"
            isDisabled={!formik.isValid || !formik.dirty || isLoading}
          >
            {t("signup.submitButtonText")}
          </Button>

          <Flex justifyContent={"flex-end"} fontSize={12}>
            <Text as={Link} to="/signin" color={useColorModeValue("primary", "blue.300")} cursor={"pointer"}>
              {t("signup.alreadyHaveAccount")}
            </Text>
          </Flex>
        </form>
      </VStack>
    </VStack>
  )
}
