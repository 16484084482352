import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AsyncState, retrieveErrorResponse } from "../../../utils";
import {
  ApiException,
  PrincipalResponse,
  TSocial,
  TSignUpUser,
} from "../../../types";
import {
  getMe,
  signin,
  signup,
  addBio,
  forgetPassword,
  resetPassword,
  uploadPhoto,
  uploadSocial,
  verifyOtp,
  recoverTutorPassword,
  updateUserInformation,
} from "../../../api/user.api";
import { deleteDatabase } from "../../../utils/db";
import { user } from "../../../Pages/interfaces/user";

type UserPayload = PrincipalResponse;

export const recoverPassword = createAsyncThunk(
  "user/recoverPassword",
  async (
    {
      email,
    }: {
      email: string;
    },
    thunkApi
  ) => {
    try {
      const info = await recoverTutorPassword(email);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const signinUser = createAsyncThunk(
  "user/signinUser",
  async (
    {
      email,
      password,
    }: {
      email: string;
      password: string;
    },
    thunkApi
  ) => {
    try {
      const info = await signin(email, password);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const signUpUser = createAsyncThunk(
  "user/signupUser",
  async (data: TSignUpUser, thunkApi) => {
    try {
      const info = await signup(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);
export function logout(): void {
  deleteDatabase();
  localStorage.clear();

  alert("Logged out successfully");
}
// export const getUserInfo = createAsyncThunk(
//   "user/getUserInfo",
//   async (data: { token: string } | undefined, thunkApi) => {
//     try {
//       const info = await getMe(data?.token);
//       return info.data;
//     } catch (e) {
//       const error = retrieveErrorResponse<ApiException>(e);
//       return thunkApi.rejectWithValue(error);
//     }
//   }
// );

const getUserInfo = createAsyncThunk(
  "user/getUserInfo",
  async (data: { token?: string } | undefined, thunkApi) => {
    try {
      const info = await getMe(data?.token);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const verifyOtpUser = createAsyncThunk(
  "user/verifyOtpUser",
  async (
    {
      otp,
      email,
    }: {
      otp: string;
      email: string;
    },
    thunkApi
  ) => {
    try {
      const info = await verifyOtp(otp, email);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const forgetPasswordUser = createAsyncThunk(
  "user/forgetPasswordUser",
  async (email: string, thunkApi) => {
    try {
      const info = await forgetPassword(email);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const resetPasswordUser = createAsyncThunk(
  "user/resetPasswordUser",
  async (
    data: {
      email: string;
      password: string;
      confirmPassword: string;
      otp: string;
    },
    thunkApi
  ) => {
    try {
      const info = await resetPassword(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const uploadPhotoUser = createAsyncThunk(
  "user/uploadPhotoUser",
  async (data: { email: string; file: File }, thunkApi) => {
    try {
      const info = await uploadPhoto(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);
export const updateUserInfo = createAsyncThunk(
  "user/updateUserInfo",
  async (data: Partial<user>, thunkApi) => {
    try {
      const info = await updateUserInformation(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const uploadSocialUser = createAsyncThunk(
  "user/uploadSocialUser",
  async (data: { social: TSocial; email: string }, thunkApi) => {
    try {
      const info = await uploadSocial(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

export const uploadBio = createAsyncThunk(
  "user/uploadBio",
  async (data: { email: string; text: string }, thunkApi) => {
    try {
      const info = await addBio(data);
      return info.data;
    } catch (e) {
      const error = retrieveErrorResponse<ApiException>(e);
      return thunkApi.rejectWithValue(error);
    }
  }
);

const initialState: AsyncState<UserPayload, ApiException> = {
  loading: false,
  payload: {},
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    clearUser: (state) => {
      state.payload = {};
    },
    signout(state) {
      state.payload = {};
      localStorage.clear();
      deleteDatabase();
      // localStorage.removeItem(principalStorageKey);
      // localStorage.removeItem(principalTokenKey);
    },
    loadUserFromStorage: (state, action: PayloadAction<UserPayload>) => {
      state.payload = action.payload;
    },
  },

  extraReducers(builder) {
    builder
      .addCase(getUserInfo.fulfilled, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(getUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(signinUser.fulfilled, (state, action) => {
        state.loading = false;
        state.payload = action.payload;
      })
      .addCase(signinUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(signinUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(signUpUser.fulfilled, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(signUpUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUpUser.rejected, (state, action) => {
        state.errors = [action.payload as ApiException];
      })
      .addCase(uploadPhotoUser.fulfilled, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(uploadPhotoUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadPhotoUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(uploadSocialUser.fulfilled, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(uploadSocialUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadSocialUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(uploadBio.fulfilled, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(uploadBio.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploadBio.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(updateUserInfo.fulfilled, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(updateUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserInfo.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(forgetPasswordUser.fulfilled, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(forgetPasswordUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(forgetPasswordUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(resetPasswordUser.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetPasswordUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetPasswordUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      })
      .addCase(verifyOtpUser.fulfilled, (state, action) => {
        state.payload = action.payload;
        state.loading = false;
      })
      .addCase(verifyOtpUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(verifyOtpUser.rejected, (state, action) => {
        state.loading = false;
        state.errors = [action.payload as ApiException];
      });
  },
});

export const userActions = {
  getUserInfo,
  updateUserInfo,
  signinUser,
  recoverPassword,
  logout,
  uploadBio,
  uploadPhotoUser,
  uploadSocialUser,
  signUpUser,
  verifyOtpUser,
  forgetPasswordUser,
  resetPasswordUser,
  ...authSlice.actions,
};

export default authSlice.reducer;
