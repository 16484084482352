import {
  Box,
  Flex,
  useColorModeValue,
  Text,
  Image,
  Heading,
  Divider,
  HStack,
} from "@chakra-ui/react";
import Group01 from "../../assets/images/Group01.png"
import Group02 from "../../assets/images/Group02.png"
// import discovery from "../../assets/images/we"
import { motion } from "framer-motion";
import "./SeeAndBuild.css";
import ImageViewer from "../common/ImageViewer";
import { MotionBox } from "../common/MotionElements";


function SeeAndBuild() {
  return (
    <Box
      p="120px 0"
      minH="100vh"
      pl={{ sm: "2%", md: "10%" }}
      pr={{ sm: "2%", md: "10%" }}
      bg={useColorModeValue("#F2F3F5", "gray.900")}
      as="section"
      maxW="100%"
      overflow="hidden"
    >
      <Box
        h="100%" pos="relative" p="1rem">

        <MotionBox
          initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}
          as={Heading}

          fontSize={{ sm: "24px", md: "80px" }}

          className="animate__fadeInUpBig"
          fontWeight={700}
          lineHeight={{ sm: "2rem", md: "5rem" }}
          mb="24px"
          mt="0"
          w={{ sm: "100%", md: "70%" }}
        >
          Share your knowledge, shape futures.
        </MotionBox>

        <Box>
          <Flex alignContent="flex-start" flexDir={{ base: "column", md: "row" }} justifyContent="space-between" alignItems="flex-start">
            <HStack>
              <Box h="20vh" w="2px" bgColor="#dce3ee" />
              <MotionBox
                initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} w="100%" h="100%" p="2rem">
                <Text fontSize={{ sm: "md", md: "24px" }}
                  fontWeight={600} color="primary">
                  Empowering Expert Tutors
                </Text>
                <Text mt="1rem" fontSize="sm" color="gray.500">
                  We meticulously select and nurture top-tier educators to deliver exceptional learning experiences.
                </Text>
              </MotionBox>
            </HStack>
            <HStack>
              <Box h="20vh" w="2px" bgColor="#dce3ee" />
              <MotionBox
                initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} w="100%" h="100%" p="2rem">
                <Text fontSize={{ sm: "md", md: "24px" }} fontWeight={600} color="primary">
                  Connecting Tutors with Learners
                </Text>
                <Text mt="1rem" fontSize="sm" color="gray.500">
                  We leverage advanced technology to match students with the perfect tutors for their learning goals.
                </Text>
              </MotionBox>
            </HStack>
            <HStack>
              <Box h="20vh" w="2px" bgColor="#dce3ee" />
              <MotionBox
                initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }} w="100%" h="100%" p="2rem">
                <Text fontSize={{ sm: "md", md: "24px" }} fontWeight={600} color="primary">
                  Measuring and Maximizing Impact
                </Text>
                <Text mt="1rem" fontSize="sm" color="gray.500">
                  We continuously analyze performance data to refine our platform and optimize student outcomes.
                </Text>
              </MotionBox>
            </HStack>
          </Flex>
        </Box>

        <MotionBox
          mt="3rem"
          initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}
        >
          <HStack flexDir={{ base: "column", md: "row" }}>
            <ImageViewer imageSrc={Group02} title="Liah" subtitle="We provide an amazing user experience and dashboard to create and mange your training programs" />
            <ImageViewer imageSrc={Group01} title="Tutor on the go" subtitle="You can always checkout how your programs are doing wherever you are." />
          </HStack> </MotionBox>
        <MotionBox
          mt="3rem"
          initial={{ opacity: 0, y: 150 }} whileInView={{ opacity: 1, y: 0 }} transition={{ duration: 0.8 }}
        >
          <HStack flexDir={{ base: "column", md: "row" }}>
            <ImageViewer imageSrc={Group01} title="Tutor on the go" subtitle="You can always checkout how your programs are doing wherever you are." />
            <ImageViewer imageSrc={Group02} title="Liah" subtitle="We provide an amazing user experience and dashboard to create and mange your training programs" />
          </HStack> </MotionBox>
      </Box>
    </Box >
  );
}

export default SeeAndBuild;
