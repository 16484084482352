import {
  Flex,
  Box,
  FormControl,
  Button as ChakraButton,
  Text,
  VStack,
  Input,
  useColorModeValue,
  InputRightElement,
  InputGroup,
  IconButton,
  Divider,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { Logo } from "../../components/common/Logo";
import { Helmet } from "react-helmet-async";
import { useGoogleAuth, useSignupLogin } from "../../hooks/auth.hooks";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { FcGoogle } from "react-icons/fc";
import { useEffect, useState } from "react";
import { useQueryParams } from "../../hooks";
import "animate.css";
import { principalTokenKey } from "../../constants";

export default function SignIn() {
  const {
    msg,
    t,
    google,
    formik,
    isLoading,
    isFormComplete,
    togglePasswordVisibility,
    showPassword,
  } = useSignupLogin("signin");

  const { loading, handleSignin } = useGoogleAuth();

  const [error, setError] = useState<string | null>(null);
  const q = useQueryParams();
  useEffect(() => {
    // Check if there is an error in the query parameters
    const errorParam = q["error"];
    const token = q["token"];

    if (errorParam) {
      setError(decodeURIComponent(errorParam));
    } else if (token) {
      // Successfully authenticated, handle token (e.g., save in localStorage)
      localStorage.setItem(principalTokenKey, token);
      // navigate('/dashboard');  // Redirect to the dashboard
    }
  }, [q]);

  return (
    <VStack
      w={{ base: "100%", md: "50%" }}
      align="flex-start"
      justify="space-between"
      h="full"
      p={{ base: 0, sm: 1, md: 4 }}
      overflowY="hidden"
    >
      <Helmet>
        <title>{t("signin.title")}</title>
        <meta name="description" content={t("signin.metaDescription")} />
      </Helmet>

      <VStack
        bg={useColorModeValue("white", "gray.800")}
        px={4}
        py={8}
        rounded="md"
        w="100%"
        align="flex-start"
      >
        <Logo />
        <Text
          as="h3"
          fontWeight="600"
          fontSize={{ base: "20px", md: "35px" }}
          mb={4}
          textAlign="left"
          w="full"
        >
          {t("signin.heading")}
        </Text>

        <FormControl w="100%">
          <ChakraButton
            leftIcon={<FcGoogle />}
            variant="googleBtn"
            w="100%"
            onClick={handleSignin}
            isLoading={loading}
            loadingText="Googling..."
          >
            {t("signin.googleSigninButton")}
          </ChakraButton>
        </FormControl>
        {q.error && (
          <Box
            className="animate__animated animate__fadeInLeft"
            textAlign="center"
            p="1rem"
            fontSize="xs"
            border="1px solid red"
            w="100%"
            rounded="md"
          >
            {/* <h2>Error during Google Sign-In</h2> */}
            <p>
              {error?.includes("dup")
                ? "The email already exists"
                : q.message === "undefined"
                ? null
                : q.error && q.message
                ? q.message.split("BadRequestException:")
                : null}
            </p>
            <p>
              {error?.includes("dup")
                ? "The email already exists"
                : q.error === "undefined"
                ? null
                : q.error}
            </p>
          </Box>
        )}
        <Flex align="center" width="full" justify="space-between" my={4}>
          <Box flex="1">
            <Divider />
          </Box>
          <Text color="gray.500" fontWeight="400" fontSize="sm" px={2}>
            {t("signin.orText")}
          </Text>
          <Box flex="1">
            <Divider />
          </Box>
        </Flex>

        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <VStack spacing={4} w="100%">
            <FormControl isRequired>
              <InputGroup>
                <Input
                  type="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={t("signin.emailPlaceholder")}
                  _placeholder={{ color: "gray.500" }}
                  rounded="md"
                  border="2px solid gainsboro"
                  bg={useColorModeValue("white", "gray.700")}
                  p={2}
                  isDisabled={google.isGoogleAuthLoading}
                  fontSize="xs"
                  w="full"
                  isInvalid={formik.touched.email && !!formik.errors.email}
                />
              </InputGroup>
            </FormControl>

            <FormControl isRequired>
              <InputGroup>
                <Input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={t("signin.passwordPlaceholder")}
                  _placeholder={{ color: "gray.500" }}
                  rounded="md"
                  border="2px solid gainsboro"
                  bg={useColorModeValue("white", "gray.700")}
                  p={2}
                  fontSize="xs"
                  w="full"
                  isDisabled={google.isGoogleAuthLoading}
                  isInvalid={
                    formik.touched.password && !!formik.errors.password
                  }
                />
                <InputRightElement>
                  <IconButton
                    size="sm"
                    variant="unstyled"
                    aria-label="Toggle password visibility"
                    icon={showPassword ? <ViewOffIcon /> : <ViewIcon />}
                    onClick={togglePasswordVisibility}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>

            <ChakraButton
              type="submit"
              variant="primary"
              w="100%"
              isDisabled={!isFormComplete() || isLoading}
              isLoading={isLoading}
              loadingText="Submitting"
            >
              {t("signin.submitButtonText")}
            </ChakraButton>

            <Flex w="100%" justify="space-between" fontSize="xs">
              <Text
                as={Link}
                to="/forgot-password"
                color="blue.500"
                _hover={{ textDecoration: "underline" }}
              >
                {t("signin.forgotPassword")}
              </Text>
              <Text
                as={Link}
                to="/signup"
                color="blue.500"
                _hover={{ textDecoration: "underline" }}
              >
                {t("signin.noAccountText")}
              </Text>
            </Flex>
          </VStack>
        </form>
      </VStack>
    </VStack>
  );
}
